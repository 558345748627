import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Divider, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';
import apiService from 'services/apiService';
import { useSelector } from 'react-redux';
import { translation } from 'utils/translate';

import './SiteInfo.css';

const SiteInfo = () => {
  const [version, setVersion] = useState(null);

  const lang = useSelector((store) => store.lang)

  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    const fetchDoc = async () => {
      try {
        const result = await apiService.fetchData(
          'version',
        );
        setVersion(result?.version);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDoc();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {translation("siteinfo", "title", lang)} | {config.site.name}
        </title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={2}>
          <div className="page-wrapper">
            <h1 className="page-title">
              {translation("about_lawradar", "title", lang)}
              <span className="page-title-version-number">
                v. {version}
              </span>
            </h1>
            <Divider />
            <p>
              {translation("about_lawradar", "about_paragraph_1", lang)}
            </p>
            <p>
              {translation("about_lawradar", "about_paragraph_2", lang)}
            </p>
            <p>
              {translation("about_lawradar", "about_paragraph_3", lang)}
            </p>
            <p>
              {translation("about_lawradar", "about_paragraph_4", lang)}
            </p>
            <p>
              {translation("about_lawradar", "about_paragraph_5", lang)}
            </p>
            <p>
              {translation("about_lawradar", "about_instructions", lang)}{' '}
              <Link to="/ohjeet">{translation("about_lawradar", "about_here", lang)}</Link>.
            </p>{' '}
            <h2 className="page-subtitle">
              {translation("about_lawradar", "interfaces", lang)}
            </h2>
            <p>
              {translation("about_lawradar", "interfaces_paragraph_1_1", lang)}{' '}
              <a
                href="https://valtioneuvosto.fi/hankkeet"
                target="_BLANK"
                rel="noopener noreferrer"
              >
                {translation("about_lawradar", "interfaces_paragraph_1_government", lang)}
              </a>{' '}
              {translation("about_lawradar", "interfaces_paragraph_1_and", lang)}{' '}
              <a
                href="https://avoindata.eduskunta.fi/"
                target="_BLANK"
                rel="noopener noreferrer"
              >
              {translation("about_lawradar", "interfaces_paragraph_1_parliament", lang)}
              </a>.{' '}
              {translation("about_lawradar", "interfaces_paragraph_1_2", lang)}
            </p>
            <p>
              {translation("about_lawradar", "interfaces_paragraph_2", lang)}
            </p>
            <p>
              {translation("about_lawradar", "interfaces_paragraph_3", lang)}
            </p>
            <p>
              {translation("about_lawradar", "interfaces_paragraph_4", lang)}
            </p>
            <p>
              {translation("about_lawradar", "interfaces_paragraph_5", lang)}
            </p>
            <p>
              <i>{translation("about_lawradar", "interfaces_swedish", lang)}</i>
            </p>
            <h2 className="page-subtitle">
              {translation("about_lawradar", "workgroup", lang)}
            </h2>
            <ul>
              <li>
                <a href="https://www.utu.fi/fi/ihmiset/anne-alvesalo-kuusi"
                target="_BLANK"
                rel="noopener noreferrer">Anne Alvesalo-Kuusi</a>
              </li>
              <li>
                <a href="https://www.utu.fi/fi/ihmiset/istvan-rytkonen"
                target="_BLANK"
                rel="noopener noreferrer">István Rytkönen</a>
              </li>
              <li>
                <a href="https://www.utu.fi/fi/ihmiset/marjaana-sjolund"
                target="_BLANK"
                rel="noopener noreferrer">Marjaana Sjölund</a>
              </li>
              <li>
                <a href="https://www.utu.fi/fi/ihmiset/emma-holkeri"
                target="_BLANK"
                rel="noopener noreferrer">Emma Holkeri</a>
              </li>
            </ul>
            <p>
              {translation("about_lawradar", "contact_1", lang)}{' '}
              <a href="mailto:lakitutka@utu.fi">lakitutka@utu.fi</a>{' '}
              {translation("about_lawradar", "contact_2", lang)}
            </p>
            <h2 className="page-subtitle">
              {translation("about_lawradar", "financing", lang)}
            </h2>
            <p>
              {translation("about_lawradar", "lakitutka_origins", lang)}
              {' '}<a href="https://www.utu.fi/"
                target="_BLANK"
                rel="noopener noreferrer">{translation("about_lawradar", "utu", lang)}</a>. 
            </p>
            <p>
              {translation("about_lawradar", "development_1", lang)}
              {' '}
              <a href="https://www.hiljaisettoimijat.fi/"
                target="_BLANK"
                rel="noopener noreferrer">{translation("about_lawradar", "development_sile", lang)}</a>{' '}
              {translation("about_lawradar", "development_2", lang)} {' '}
              <a href="https://lawpol.fi/"
                target="_BLANK"
                rel="noopener noreferrer">{translation("about_lawradar", "development_lawpol", lang)}</a>{' '}
              {translation("about_lawradar", "development_3", lang)}
            </p>
            <h3 className="page-subtitle">
              {translation("about_lawradar", "sile", lang)}
            </h3>
            <img
              alt="SILE - Hiljaiset toimijat ja laki"
              src="/sile.png"
              className='funded-by'
            />
            <p>
              {translation("about_lawradar", "sile_numbers", lang)}
            </p>
            <p>
              <a href="https://www.hiljaisettoimijat.fi/"
                target="_BLANK"
                rel="noopener noreferrer">
                  {translation("about_lawradar", "lawpol_link", lang)}
              </a>
            </p>
            <h3 className="page-subtitle">
              {translation("about_lawradar", "lawpol", lang)}
            </h3>
            <img
              alt="Euroopan unionin rahoittama NextGenerationEU"
              src="/FI_Euroopan_unionin_rahoittama.png"
              className='funded-by'
            />
            <p>
              {translation("about_lawradar", "lawpol_numbers", lang)}
            </p>
            <p>
              <a href="https://lawpol.fi/"
                target="_BLANK"
                rel="noopener noreferrer">
                {translation("about_lawradar", "lawpol_link", lang)}
              </a>
            </p>
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default SiteInfo;
