import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Divider, Paper } from '@material-ui/core';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';
import './Instructions.css';

const videos = [
  {
    title: {
      fi: 'Perustoiminnot',
      se: 'Basfunktioner',
    },
    url: 'https://www.youtube.com/embed/Bx7ZP61kGiQ?enablejsapi=1',
  },
  {
    title: {
      fi: 'Esirajaus, proksimiteettihaku ja visualisointipuolen suodattimet',
      se: 'Filter för förhandsbegränsning, proximitetssökning och visualisering',
    },
    url: 'https://www.youtube.com/embed/wmKf8W-SwDA?enablejsapi=1',
  },
  {
    title: {
      fi: 'Aikajana',
      se: 'Tidsaxel',
    },
    url: 'https://www.youtube.com/embed/76YpPtn4rWY?enablejsapi=1',
  },
  {
    title: {
      fi: 'Metatietojen lataaminen',
      se: 'Nerladdning av metadata',
    },
    url: 'https://www.youtube.com/embed/RChjhxnY9kg?enablejsapi=1',
  },
];

const Instructions = () => {
  const lang = useSelector((store) => store.lang)
  
  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Helmet>
        <title>{translation("instructions", "short_title", lang)} | {config.site.name}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={2}>
          <div className="page-wrapper">
            <h1 className="page-title">
            {translation("instructions", "long_title", lang)}
            </h1>
            <Divider />
            <p>
              <a
                className="big-link"
                href="https://lakitutka.fi/media/Lakitutka_ohjeet.pdf"
              >
                {translation("instructions", "download_instructions", lang)}
              </a>
            </p>
            <p>
              <strong>
                {translation("instructions", "note", lang)}
              </strong>
            </p>
            {videos.map((video) => (
              <>
                <h2 className="page-subtitle">
                  {lang === 'se' ? video.title.se : video.title.fi }
                </h2>
                <iframe
                  className="embed-video"
                  title="video"
                  width="700"
                  height="394"
                  src={video.url}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </>
            ))}
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default Instructions;
