/* eslint-disable no-shadow */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  Grid,
  Paper,
  Chip,
  TextField,
  Button,
  Hidden,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import ChartIcon from '@material-ui/icons/BarChart';
import DownloadIcon from '@material-ui/icons/GetApp';
import Pagination from 'components/Pagination';
import DownloadForm from 'components/DownloadForm';
import Tip from 'components/Tip';
import config from 'config';
import {
  initializeFilters,
  randomId,
  isProductionSite
} from 'utils/commonTools';
import { streamFileToUser } from 'utils/fileTools';
import apiService from 'services/apiService';
import {
  clearError,
  reportError,
} from 'actions/errorActions';
import { translateKeyword, translation } from 'utils/translate';
import FacetBlock from './FacetBlock';
import SearchResults from './SearchResults';
import PreFilters from './PreFilters';
import ResultCharts from './ResultCharts';
import ResultChooser from './ResultChooser';
import './SearchForm.css';

const sortOptions = [
  'score',
  'date_asc',
  'date_desc',
  'alpha_asc',
  'alpha_desc',
];
const sortDefault = 'alpha_desc';
const sizeOptions = [5, 10, 25, 50, 100, 250, 500];
const sizeDefault = 10;

const formTypes = isProductionSite ? ['basic', 'proximity'] : ['basic', 'semantic', 'proximity',];

const initialProximityValue = {
  term1: '',
  term2: '',
  slop: 1,
};

const urlifyJson = (value) =>
  encodeURIComponent(JSON.stringify(value));

// returns true if given item has a value and it's not an empty array
const hasContent = (item) =>
  item && !(Array.isArray(item) && !item.length);

const setDefaultSort = (query, docGroup) => {
  if (query.sort) {
    return query.sort
  }

  if (docGroup === 'all') {
    return 'score'
  }

  if (query.term) {
    return 'score'
  }

  return sortDefault
}


/* -------------------- *\
  SEARCH FORM -COMPONENT
\* -------------------- */
class SearchForm extends Component {
  constructor(props) {
    super(props);
    const { location, docGroup, facetConstants, lang } =
      this.props;
    const values = queryString.parse(location.search);
    this.componentIsMounted = false;
    this.searchParams = '';
    this.facetFields =
      config.docGroups[docGroup].facetFields;
    const proximityValue = values.proximity
      ? JSON.parse(values.proximity)
      : null;
    this.state = {
      term: values.term || '',
      submittedTerm: values.term || '',
      activeFormType: proximityValue
        ? 'proximity'
        : 'basic',
      sort: setDefaultSort(values, docGroup),
      manualSort: false,
      size: parseInt(values.size, 10) || sizeDefault,
      page: parseInt(values.page, 10) || 1,
      pagesTotal: 0,
      preFilters: {},
      filters: initializeFilters(this.facetFields),
      proximityValue:
        proximityValue || initialProximityValue,
      submittedProximityValue: proximityValue || {},
      totalHits: facetConstants[docGroup]?.total,
      isLoaded: false,
      result: {},
      facetCacheForBlocks: {},
      facetCacheForPrefilters: {},
      showDownloadForm: false,
      visualize: false,
      loadingDataFromBackend: false,
      loadingPercent: 0,
      fileDownloadLink: '',
      searchSubDocuments: false,
      lang: lang
    };
    // Create ref-objects
    this.searchFormRef = React.createRef();
    this.searchInfoRef = React.createRef();
  }

  resetSearch = async () => {
    this.clearFilters();
    this.clearPreFilters();
    this.searchParams = '';
    await this.setState({
      term: '',
      submittedTerm: '',
      page: 1,
      isLoaded: false,
      result: {},
      facetCacheForBlocks: {},
      facetCacheForPrefilters: {},
      visualize: false,
      sort: sortDefault,
      manualSort: false
    });
    this.pushHistory();
  };

  async componentDidMount() {
    const { location, facetConstants, docGroup } = this.props;
    this.componentIsMounted = true;
    this.clearPreFilters();

    // if search parameters in url, handle them and fetch needed searches
    if (location.search) {
      const values = queryString.parse(location.search);
      if (values.prefilters) {
        try {
          const parsedPreFilters = JSON.parse(
            values.prefilters,
          );
          await this.setState({
            preFilters: parsedPreFilters,
          });
          await this.fetchSearch('prefilter');
        } catch (error) {
          // eslint-disable-next-line no-console
          console.warn(error);
        }
      }
      await this.fetchSearch();
      this.setState({
        // populate filters from url values
        filters: this.facetFields.reduce(
          (soFar, field) => ({
            ...soFar,
            [field]: !values[field]
              ? []
              : Array.isArray(values[field])
                ? values[field]
                : [values[field]],
          }),
          [],
        ),
        searchSubDocuments: values["asiakirjat.tyyppi"] ? true : false,
        totalHits: values["asiakirjat.tyyppi"] ? facetConstants[docGroup + '_asiakirjat']?.total : facetConstants[docGroup]?.total,
      });
      if (this.hasFilters()) {
        await this.fetchSearch('update');
      }
    }

    // Set charts ON if visualize in query params
    const searchParams = new URLSearchParams(
      window.location.search,
    );
    if (searchParams.get('visualize')) {
      this.setState({ visualize: true });
    }
  }

  async componentDidUpdate(prevProps) {
    const { history, docGroup, facetConstants, location, lang } =
      this.props;

    if (lang !== this.state.lang) {
      this.setState({
        lang: lang
      })
      await this.fetchSearch();
    }

    if (location.pathname !== prevProps.location.pathname) {
      if (location.hash === '#basic-search-term') {
        this.resetSearch();
      }
    }

    if (docGroup !== prevProps.docGroup) {
      const { submittedTerm } = this.state;
      this.facetFields =
        config.docGroups[docGroup].facetFields;
      // eslint-disable-next-line react/no-did-update-set-state
      await this.setState({
        isLoaded: false,
        page: 1,
        pagesTotal: facetConstants[docGroup]?.total,
        facetCacheForBlocks: {},
        facetCacheForPrefilters: {},
        result: {},
        showDownloadForm: false,
      });
      this.clearFilters();
      this.clearPreFilters();
      if (submittedTerm) {
        await this.fetchSearch();
        this.pushHistory();
      } else {
        history.push({
          pathname: `/haku/${docGroup}`,
        });
      }
    }
  }

  componentWillUnmount() {
    this.componentIsMounted = false;
  }

  pushHistory = () => {
    const { history, docGroup } = this.props;
    history.push({
      pathname: `/haku/${docGroup}`,
      search: this.searchParams,
    });
  };

  scrollToSearchFormTop = () => {
    window.scrollTo({
      top: this.searchFormRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  scrollToResultsTop = () => {
    window.scrollTo({
      top: this.searchInfoRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  gotoPage = async (newPage) => {
    await this.setState({
      page: newPage,
    });
    await this.fetchSearch('update');
    this.pushHistory();
    this.scrollToResultsTop();
  };

  // return search parameter string for API call and browser history push
  buildSearchParams = () => {
    const {
      submittedTerm,
      filters,
      page,
      preFilters,
      submittedProximityValue,
      size,
      visualize,
    } = this.state;

    const {
      facetConstants,
      docGroup,
      lang
    } = this.props;

    const params = {
      term: submittedTerm.replace('&', ' '), // TODO: better sanitation
      ...filters,
      page: page > 1 && page,
      size: size !== sizeDefault && size,
      sort: this.setSort(),
      prefilters:
        Object.keys(preFilters).length > 0 &&
        urlifyJson(preFilters),
      proximity:
        submittedProximityValue.term1 &&
        submittedProximityValue.term2 &&
        urlifyJson(submittedProximityValue),
      visualize: visualize ? 1 : null,
      lang: lang
    };

    try {
      this.setState({
        searchSubDocuments: (filters["asiakirjat.tyyppi"] && filters["asiakirjat.tyyppi"].length > 0) ? true : false,
        totalHits: (filters["asiakirjat.tyyppi"] && filters["asiakirjat.tyyppi"].length > 0) ? facetConstants[docGroup + '_asiakirjat']?.total : facetConstants[docGroup]?.total,
      })
    } catch (error) {
      this.setState({ searchSubDocuments: false})
    }

    return `?${Object.entries(params)
      .filter((entry) => hasContent(entry[1]))
      .flatMap(([key, value]) =>
        Array.isArray(value)
          ? value.map((item) => `${key}=${item}`)
          : `${key}=${value}`,
      )
      .join('&')}`;
  };

  // return prefilter search parameter string for API call and browser history push
  buildPrefilterParams = () => {
    const { preFilters, size } = this.state;
    const params = {
      size: size !== sizeDefault && size,
      sort: this.setSort(),
      prefilters:
        Object.keys(preFilters).length > 0 &&
        urlifyJson(preFilters),
    };
    return `?${Object.entries(params)
      .filter((entry) => hasContent(entry[1]))
      .flatMap(([key, value]) => `${key}=${value}`)
      .join('&')}`;
  };

  // fetch search from api and put result in state
  async fetchSearch(fetchType = 'normal') {
    const { size, activeFormType } = this.state;
    const { docGroup, clearError, reportError } =
      this.props;

    try {
      this.searchParams =
        fetchType !== 'prefilter'
          ? this.buildSearchParams()
          : this.buildPrefilterParams();

      let result = null
      try {

        let searchIndex = docGroup

        if (this.state.searchSubDocuments) {
          searchIndex = docGroup + "_asiakirjat"
        }

        if (activeFormType === 'semantic') {
          result = await apiService.fetchData(
            `semsearch/${searchIndex}${this.searchParams}`,
          );
        } else {
          result = await apiService.fetchData(
            `search/${searchIndex}${this.searchParams}`,
          );
        }
      } catch (error) {
        reportError(error)
        return
      }

      // ES response changed in major version update from v6 to v7
      if (
        typeof result.hits.total === 'object' &&
        'value' in result.hits.total
      ) {
        result.hits.total = result.hits.total.value;
      }

      if (this.componentIsMounted) {
        switch (fetchType) {
          // don't overwrite aggregations (ie. facets)
          case 'update':
            this.setState({
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              result,
            });
            break;

          case 'prefilter':
            this.setState({
              isLoaded: true,
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              facetCacheForPrefilters: result.aggregations,
              facetCacheForBlocks: result.aggregations,
              totalHits: result.hits.total,
              result,
            });
            break;

          case 'normal':
          default:
            this.setState({
              isLoaded: true,
              pagesTotal: Math.ceil(
                result.hits.total / size,
              ),
              facetCacheForBlocks: result.aggregations,
              result,
            });
            break;
        }
        clearError();
      }
    } catch (error) {
      if (this.componentIsMounted) {
        reportError(error);
      }
    }
  }

  // functions for basic search form
  handleTermChange = (event) =>
    this.setState({
      term: event.target.value,
      proximityValue: initialProximityValue,
    });

  handleBasicSearchSubmit = async (event) => {
    event.preventDefault();
    const { term } = this.state;
    this.clearFilters();
    await this.setState({
      submittedTerm: term,
      submittedProximityValue: {},
      page: 1,
    });
    await this.fetchSearch();
    this.scrollToSearchFormTop();
    this.pushHistory();
  };

  // functions for proximity search form
  handleProximityValueChange = (event) => {
    const { proximityValue } = this.state;
    const { target } = event;
    this.setState({
      term: '',
      proximityValue: {
        ...proximityValue,
        [target.name]: target.value,
      },
    });
  };

  handleProximitySearchSubmit = async (event) => {
    const { proximityValue } = this.state;
    event.preventDefault();
    this.clearFilters();
    await this.setState({
      submittedTerm: '',
      submittedProximityValue: proximityValue,
      page: 1,
    });
    await this.fetchSearch();
    this.scrollToSearchFormTop();
    this.pushHistory();
  };

  handleResultSizeChange = async (event) => {
    const { size } = this.state;
    if (size !== event.target.value) {
      await this.setState({
        page: 1,
        size: event.target.value,
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  };

  handleResultSortChange = async (event) => {
    const { sort } = this.state;
    if (sort !== event.target.value) {
      await this.setState({
        page: 1,
        sort: event.target.value,
        manualSort: true
      });
      await this.fetchSearch();
      this.pushHistory();
      this.scrollToResultsTop();
    }
  };

  setSort = () => {
    const { sort, submittedTerm, manualSort } = this.state;

    if (manualSort) {
      return sort
    }

    if (!submittedTerm) {
      this.setState({
        sort: sortDefault
      })
      return sortDefault
    }

    if (sort === sortDefault && submittedTerm) {
      this.setState({
        sort: 'score'
      })
      return 'score'
    }

    return sort
  }

  // functions for search filters
  clearFilters = () => {
    this.setState({
      filters: initializeFilters(this.facetFields),
    });
  };

  clearPreFilters = () => {
    const { docGroup, facetConstants } = this.props;
    this.setState({
      totalHits: facetConstants[docGroup]?.total,
      preFilters: {},
      facetCacheForPrefilters: {},
    });
  };

  hasFilters = () => {
    const { filters } = this.state;
    return this.facetFields.some(
      (field) => filters[field].length > 0,
    );
  };

  activeSearchParameters = () => {
    return !(
      this.state.result &&
      Object.keys(this.state.result).length === 0 &&
      Object.getPrototypeOf(this.state.result) ===
      Object.prototype
    );
  };

  // functions for facets
  handleFacetChange = async (group, value) => {
    const { filters } = this.state;
    const strValue = `${value}`;

    const newFilters = {
      ...filters,
      [group]: filters[group].includes(strValue)
        ? [
          ...filters[group].filter(
            (item) => item !== strValue,
          ),
        ]
        : [...filters[group], strValue],
    };
    await this.setState({
      page: 1,
      filters: newFilters,
    });
    await this.fetchSearch('update');
    this.pushHistory();
  };

  removeAllFacets = async () => {
    await this.setState({
      page: 1,
    });
    this.clearFilters();
    await this.fetchSearch();
    this.pushHistory();
  };

  // functions for preFilters
  handlePreFiltersSubmit = async (
    event,
    preFiltersInput,
  ) => {
    event.preventDefault();
    this.clearFilters();
    // filter out empty strings and arrays from preFiltersInput
    const filteredInput = Object.entries(
      preFiltersInput,
    ).reduce(
      (soFar, [key, value]) =>
        hasContent(value)
          ? {
            ...soFar,
            [key]: value,
          }
          : soFar,
      {},
    );
    await this.setState({
      submittedTerm: '',
      submittedProximityValue: {},
      page: 1,
      preFilters: filteredInput,
    });
    await this.fetchSearch('prefilter');
    this.scrollToSearchFormTop();
    this.pushHistory();
  };

  toggleDownloadForm = () => {
    const { showDownloadForm } = this.state;
    this.setState({
      showDownloadForm: !showDownloadForm,
      fileDownloadLink: '',
      loadingDataFromBackend: false,
    });
  };

  toggleVisualize = () => {
    const { visualize } = this.state;
    this.setState({
      visualize: !visualize,
      showDownloadForm: false,
    });

    if (!visualize) {
      const params = new URLSearchParams(
        window.location.search,
      );
      params.set('visualize', 1);
      const newRelativePathQuery = `${window.location.pathname
        }?${params.toString()}`;
      window.history.pushState(
        null,
        '',
        newRelativePathQuery,
      );
    } else {
      const params = new URLSearchParams(
        window.location.search,
      );
      params.delete('visualize');
      const newRelativePathQuery = `${window.location.pathname
        }?${params.toString()}`;
      window.history.pushState(
        null,
        '',
        newRelativePathQuery,
      );
    }
  };

  downloadSearchResults = async ({
    metaFormat,
    fileFormats,
  }) => {
    const { docGroup, reportError } = this.props;
    // show spinner
    this.setState({
      loadingDataFromBackend: true,
    });

    let jobId = '';
    if (fileFormats) {
      jobId = randomId(8);
    }

    try {
      const result = await apiService.fetchData(
        `download/${docGroup}${this.searchParams}&format=${metaFormat}&content=${fileFormats}&job_id=${jobId}`,
        'text',
      );

      if (result) {
        streamFileToUser({
          data: result,
          format: metaFormat,
          fileName: `${docGroup}_exported`,
        });
      }

      if (fileFormats) {
        const interval = setInterval(async () => {
          try {
            const { status } =
              await apiService.checkFileStatus(jobId);

            if (status === 'DONE') {
              clearInterval(interval);
              this.setState({
                fileDownloadLink: jobId,
                loadingDataFromBackend: false,
              });
              // eslint-disable-next-line no-restricted-globals
            } else if (!isNaN(status)) {
              this.setState({
                loadingPercent: parseFloat(status),
              });
            } else {
              clearInterval(interval);
              this.setState({
                loadingDataFromBackend: false,
                showDownloadForm: false,
              });
              reportError(status);
            }
          } catch (err) {
            console.log(err);
            // pass (this error will be handled by parent)
          }
        }, 3000);
      } else {
        this.setState({
          loadingDataFromBackend: false,
          showDownloadForm: false,
        });
      }
    } catch (error) {
      this.setState({
        loadingDataFromBackend: false,
        showDownloadForm: false,
      });
      reportError(error);
    }
  };

  handleDownloadZippedFile = () => {
    this.setState({
      loadingDataFromBackend: false,
    });
  };

  // prefilter and search forms for rendering
  renderSearchForm() {
    const {
      activeFormType,
      term,
      preFilters,
      proximityValue,
      totalHits
    } = this.state;

    const { docGroup, lang } = this.props;

    return totalHits ? (
      <>
        <div className="search-form-wrapper">
          <PreFilters
            key={docGroup}
            startValues={preFilters}
            docGroup={docGroup}
            handlePreFiltersSubmit={
              this.handlePreFiltersSubmit
            }
            handlePreFiltersClear={this.resetSearch}
          />
          <div
            className="form-type-select-area"
            ref={this.searchFormRef}
          >
            {formTypes.map((key) => (
              <Tip title={translation("search", key, "tooltip", lang)} key={key}>
                <button
                  type="button"
                  className={
                    activeFormType === key
                      ? 'form-type-select form-type-select--active'
                      : 'form-type-select'
                  }
                  onClick={() =>
                    this.setState({
                      activeFormType: key,
                    })
                  }
                >
                  {translation("search", key, "name", lang)}
                </button>
              </Tip>
            ))}
          </div>

          {activeFormType === 'basic' || activeFormType === 'semantic' ? (
            // Basic search form
            <form
              className="search-form search-form--basic"
              onSubmit={this.handleBasicSearchSubmit}
            >
              <TextField
                id="basic-search-term"
                className="basic-search-term"
                value={term}
                name="hakutermi"
                onChange={this.handleTermChange}
                key={
                  translation("doc_groups", docGroup, "search", lang)
                }
                label={
                  translation("doc_groups", docGroup, "search", lang)
                }
                variant="outlined"
              />
              <Button
                aria-label="hae"
                variant="outlined"
                type="submit"
                className="submit-basic-search"
                disableTouchRipple
              >
                <SearchIcon />
              </Button>
              <div className="fetch-all-clear-all-button">
                {this.activeSearchParameters() ? (
                  <Button
                    aria-label="hae kaikki"
                    variant="outlined"
                    disableTouchRipple
                    onClick={this.resetSearch}
                  >
                    {translation("search", "empty", lang)}
                  </Button>
                ) : (
                  <Button
                    aria-label="tyhjennä"
                    variant="outlined"
                    disableTouchRipple
                    onClick={this.handleBasicSearchSubmit}
                  >
                    {translation("search", "all", lang)}
                  </Button>
                )}
              </div>
            </form>
          ) : (
            // Proximity search form
            <form
              className="search-form search-form--proximity"
              onSubmit={this.handleProximitySearchSubmit}
            >
              <TextField
                id="proximity-input-first"
                className="proximity-search-input search-input--left"
                label={translation("search", "proximity", "first_term", lang)}
                name="term1"
                value={proximityValue.term1}
                onChange={this.handleProximityValueChange}
                variant="outlined"
              />
              <TextField
                id="proximity-input-max"
                className="proximity-search-input search-input--center"
                name="slop"
                label={translation("search", "proximity", "distance", lang)}
                value={proximityValue.slop}
                onChange={this.handleProximityValueChange}
                type="number"
                inputProps={{
                  min: '0',
                  max: '1000',
                  step: '1',
                }}
                variant="outlined"
              />
              <TextField
                id="proximity-input-second"
                className="proximity-search-input search-input--right"
                name="term2"
                label={translation("search", "proximity", "second_term", lang)}
                value={proximityValue.term2}
                onChange={this.handleProximityValueChange}
                variant="outlined"
              />
              <Button
                aria-label="hae"
                variant="outlined"
                type="submit"
                className="submit-basic-search"
                disableTouchRipple
              >
                <SearchIcon />
              </Button>
            </form>
          )}
        </div>
      </>
    ) : <div>Hakukone poissa käytöstä hetkellisesti. Pahoittelut häiriöstä.</div>
  }

  // search result list, pagination and sorting etc. tools for rendering
  renderSearchResultsArea() {
    const {
      submittedTerm,
      submittedProximityValue,
      activeFormType,
      isLoaded,
      result,
      page,
      pagesTotal,
      size,
      sort
    } = this.state;

    const { docGroup, lang } = this.props;


    if (!isLoaded || pagesTotal <= 0) {
      return null;
    }
    const { hits } = result.hits;
    let pagination = null;
    if (pagesTotal > 1) {
      pagination = (
        <Paper elevation={2}>
          <Pagination
            page={page}
            pagesTotal={pagesTotal}
            gotoPage={this.gotoPage}
          />
        </Paper>
      );
    }
    return (
      <div id="results" className="results-area">
        <div className="result-chooser-area">
          <div className="result-chooser-wrapper">
            {translation("results", "order", lang)}:
            <ResultChooser
              value={sort}
              options={sortOptions}
              handleChange={this.handleResultSortChange}
            />
          </div>
          <div className="result-chooser-wrapper">
            {translation("results", "on_page", lang)}:
            <ResultChooser
              value={size}
              options={sizeOptions}
              handleChange={this.handleResultSizeChange}
            />
          </div>
        </div>
        {pagination}
        <Paper elevation={2}>
          <SearchResults
            hits={hits}
            docGroup={docGroup}
            highlightTerm={
              activeFormType === 'proximity'
                ? `${submittedProximityValue.term1}%20OR%20${submittedProximityValue.term2}`
                : submittedTerm
            }
            page={page}
            size={size}
            searchTerm={submittedTerm}
          />
        </Paper>
        {pagination}
      </div>
    );
  }

  // info text area from current search and preFilters for rendering
  renderSearchInfo() {
    const {
      submittedTerm,
      isLoaded,
      preFilters,
      submittedProximityValue,
      totalHits,
      result
    } = this.state;

    const { lang } = this.props

    if (!result.hits || !isLoaded) {
      return null;
    }

    const activePreFilterNames = Object.entries(preFilters)
      .filter((keyValuePair) => hasContent(keyValuePair[1]))
      .map(([key, value]) => {
        const label = translateKeyword(key, lang);
        const selection = Array.isArray(value)
          ? value.join(', ')
          : value;
        return `${label} (${selection})`;
      })
      .join(', ');

    return (
      <>
        {/* if prefilters */}
        {activePreFilterNames && (
          <div className="search-info">
            <strong>{translation("results", "prefilters", lang)}:</strong>{' '}
            <i>{activePreFilterNames}</i>
          </div>
        )}
        <div className="search-info">
          {/* if basic search with term */}
          {submittedTerm && (
            <>
              {translation("results", "term", lang)}: <strong>{submittedTerm}</strong>,{' '}
            </>
          )}
          {/* if proximity search */}
          {submittedProximityValue.term1 &&
            submittedProximityValue.term2 && (
              <>
                {translation("search", "proximity", "name", lang)}:{' '}
                <strong>
                  {submittedProximityValue.term1}
                </strong>{' '}
                {'<max '} {submittedProximityValue.slop}{' '}
                {' ' + translation("search", "proximity", "words", lang) + '>'}{' '}
                <strong>
                  {submittedProximityValue.term2}
                </strong>
                ,{' '}
              </>
            )}
          {/* hit stats */}
          {translation("results", "hits", lang)}: {result.hits.total}/{totalHits} (
          {((result.hits.total / totalHits) * 100).toFixed(
            1,
          )}
          %)
        </div>
      </>
    );
  }

  renderResultTools() {
    const { result, visualize } = this.state;
    const { lang } = this.props;
    return (
      result &&
      result.hits &&
      result.hits.total > 0 && (
        <div className="button-area">
          <Button
            className="button--right"
            disableTouchRipple
            onClick={this.toggleVisualize}
          >
            {visualize ? (
              <>
                <ListIcon className="button-icon" />
                  {translation("results", "show_results", lang)}
              </>
            ) : (
              <>
                <ChartIcon className="button-icon" />
                  {translation("results", "show_visual", lang)}
              </>
            )}
          </Button>
          <Button
            disableTouchRipple
            onClick={this.toggleDownloadForm}
          >
            <DownloadIcon className="button-icon" />
            {translation("results", "download", lang)}
          </Button>
        </div>
      )
    );
  }

  // active facet chips (if any) for rendering
  renderActiveFacets() {
    const { filters, isLoaded } = this.state;
    const { lang } = this.props;
    if (!isLoaded || !this.hasFilters()) {
      return null;
    }
    return (
      <div className="facet-active-wrapper">
        {this.facetFields.map(
          (field) =>
            filters[field] &&
            filters[field].map((item) => (
              <Chip
                key={item}
                label={item}
                onDelete={() =>
                  this.handleFacetChange(field, item)
                }
                className="facet-chip"
              />
            )),
        )}
        <Chip
          label={translation("results", "remove_active_facets", lang)}
          onClick={() => this.removeAllFacets()}
          className="facet-chip remove-all"
        />
      </div>
    );
  }

  // facet blocks for rendering
  renderFacetBlocks() {
    const {
      facetCacheForBlocks,
      filters,
      result,
      isLoaded,
      pagesTotal,
    } = this.state;

    if (
      !isLoaded ||
      (pagesTotal <= 0 && !this.hasFilters())
    ) {
      return null;
    }

    return (
      <div className="facets">
        {this.facetFields.map(
          (field) => (
            result.aggregations[field] && (
              <FacetBlock
                key={field}
                field={field}
                onChange={(event) =>
                  this.handleFacetChange(
                    event.target.getAttribute('group'),
                    event.target.value,
                  )
                }
                facets={result.aggregations[field].buckets}
                cachedFacets={field in facetCacheForBlocks ? facetCacheForBlocks[field].buckets : []}
                filters={filters[field]}
              />
            )
          )
        )}
      </div>
    );
  }

  // Render it all
  render() {
    const {
      facetCacheForPrefilters,
      result,
      showDownloadForm,
      visualize,
      loadingDataFromBackend,
      loadingPercent,
      fileDownloadLink,
    } = this.state;
    const { docGroup, lang } = this.props;

    return (
      <div className="search" role="main">
        <Hidden smUp>
          <div className="search-docgroup-info">
            <Paper
              elevation={2}
              className="search-docgroup-info__inner"
            >
              <p>
                {translation("doc_groups", docGroup, "text", lang)}
              </p>
              <p>
                {translation("doc_groups", docGroup, "source", lang)}
              </p>
            </Paper>
          </div>
        </Hidden>
        {this.renderSearchForm()}

        <div
          className="search-info-area"
          ref={this.searchInfoRef}
        >
          {this.renderSearchInfo()}
          {this.renderActiveFacets()}
          {this.renderResultTools()}
        </div>

        {showDownloadForm ? (
          <div className="download-form-wrapper download-form-wrapper--open">
            <DownloadForm
              loadingDataFromBackend={
                loadingDataFromBackend
              }
              loadingPercent={loadingPercent}
              fileDownloadLink={fileDownloadLink}
              handleDownload={this.downloadSearchResults}
              handleDownloadZippedFile={
                this.handleDownloadZippedFile
              }
            />
          </div>
        ) : (
          <div className="download-form-wrapper" />
        )}

        {visualize ? (
          // Show either search data visualization...
          result &&
          result.hits &&
          result.hits.total > 0 && (
            <Grid container spacing={4}>
              <ResultCharts
                docGroup={docGroup}
                dataset={result.aggregations || {}}
                cache={facetCacheForPrefilters}
                handleFacetChange={this.handleFacetChange}
              />
            </Grid>
          )
        ) : (
          // ...or search results and facets
          <Grid container spacing={4}>
            <Grid item xs={12} sm={7} md={8}>
              {this.renderSearchResultsArea()}
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
              {this.renderFacetBlocks()}
            </Grid>
          </Grid>
        )}
      </div>
    );
  }
}

SearchForm.propTypes = {
  docGroup: PropTypes.string.isRequired,
  facetConstants: PropTypes.shape({}).isRequired,
  lang: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  clearError: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  facetConstants: state.facetConstants,
  lang: state.lang,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ reportError, clearError }, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchForm),
);
