import {
  applyMiddleware,
  createStore,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import facetConstantsReducer from './reducers/facetConstantsReducer';
import errorReducer from './reducers/errorReducer';
import searchContextReducer from './reducers/searchContextReducer';
import langReducer from 'reducers/langReducer';

const reducers = combineReducers({
  // refactor searchform to function component in order to save search state to context
  searchContext: searchContextReducer,
  facetConstants: facetConstantsReducer,
  error: errorReducer,
  lang: langReducer
});

const middlewares = [thunk];

export default function configureStore(preloadedState) {
  return createStore(
    reducers,
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
}
