import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { translation, translateKeyword } from 'utils/translate';
import { sortByKey } from 'utils/commonTools';
import Block from 'components/Block';
import ShowMore from 'components/ShowMore';
import './FacetBlock.css';
import { Paper, Grid } from '@material-ui/core';
import Tip from 'components/Tip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useSelector } from 'react-redux';

// make string html attribute compiliant
const safe = (input) =>
  input.toLowerCase().replace(/[^a-z0-9åäö]/g, '_');

/* facet sorting label and arrows indicating sorting order */
function FacetOrderingLabel({
  correctKey,
  label,
  labelStyling = 'p',
  direction,
}) {
  const customLabel = React.createElement(
    labelStyling,
    null,
    label,
  );

  return (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      wrap="nowrap"
      direction="row"
    >
      <Grid item xs={10}>
        {customLabel}
      </Grid>
      <Grid container direction="column">
        <Grid item xs={2}>
          <ArrowDropUpIcon
            style={{
              color:
                direction === 'down' && correctKey
                  ? ' blue'
                  : 'black',
            }}
            className="facet-arrow-up"
          />
        </Grid>
        <Grid item xs={2}>
          <ArrowDropDownIcon
            style={{
              color:
                direction === 'up' && correctKey
                  ? ' blue'
                  : 'black',
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

/* FACET BLOCK COMPONENT */

// PROPS:
// facets: aggreration buckets from Elasticsearch
// cachedFacets: aggreration buckets from Elasticsearch before filters
// field: name of the facet field
// filters: list of active filters on the field
// onChange: onChange -function to toggle facet
// sorting: initial sorting order (count/alpha), default is 'count'
function FacetBlock({
  facets,
  cachedFacets,
  field,
  filters,
  onChange,
  sorting: initialSorting,
}) {
  const defDirections = { alpha: 'asc', count: 'desc' };
  const topRef = useRef();
  const checkBoxes = useRef([]);
  const [sorting, setSorting] = useState(initialSorting);
  const [sortDirection, setSortDirection] = useState(
    defDirections[initialSorting],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [facetRenderList, setFacetRenderList] =
    useState(false);

  const lang = useSelector((store) => store.lang)

  // scroll to top of the facet block
  const scrollToTop = () => {
    window.scrollTo({
      top: topRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  // toggle 'show more/less'
  const toggleOpen = () => {
    setIsOpen(!isOpen);
    scrollToTop();
  };

  const handleSortingChange = (event) => {
    const newType =
      event.currentTarget.getAttribute('sorttype');
    if (sorting !== newType) {
      setSorting(newType);
      setSortDirection(defDirections[newType]);
    } else {
      setSortDirection(
        sortDirection === 'asc' ? 'desc' : 'asc',
      );
    }
  };

  useEffect(() => {
    const notFilters = filters
      .filter((item) => item.includes('EI'))
      .map((item) => item.replace('EI ', ''));

    let facetList = []

    if (facets.length < cachedFacets.length) {
      facetList = cachedFacets.map((item) => {
        const referenceItem = facets.find(
          (subItem) => subItem.key === item.key,
        );

        return {
          ...item,
          displayedDocCount: referenceItem
            ? referenceItem.doc_count
            : 0,
        };
      })
    } else {
      facetList = facets.map((item) => {
        Object.assign(item, { "displayedDocCount": item["doc_count"] });
        return item
      })
    }

    setFacetRenderList(
      isOpen
        ? sortByKey(
          facetList,
          sorting === 'alpha' ? 'key' : 'displayedDocCount',
          sortDirection,
        ).filter(
          (item) => !notFilters.includes(String(item.key)),
        )
        : sortByKey(
          facetList,
          sorting === 'alpha' ? 'key' : 'displayedDocCount',
          sortDirection,
        )
          .filter(
            (item) =>
              !notFilters.includes(String(item.key)),
          )
          .slice(0, 5)
    );
  }, [
    cachedFacets,
    filters,
    facets,
    isOpen,
    sortDirection,
    sorting,
    field
  ]);

  function getReactProps(el) {
    const keys = Object.keys(el);
    const propKey = keys.find((key) =>
      key.includes('__reactEventHandlers'),
    );
    return el[propKey];
  }

  const selectAllButTHis = (checkBoxIndex) => {
    const el = checkBoxes.current[checkBoxIndex];
    const ev = new Event('input', { bubbles: true });
    el.value = `EI ${el.value}`;
    el.dispatchEvent(ev);
    getReactProps(el).onChange(ev);
  };

  return (
    <Paper elevation={2}>
      <Block className="block--facet" ref={topRef}>
        <div className="facet-header-wrapper">
          <Tip title={translation("facets", "tooltip", field, lang)}>
            <button
              type="button"
              className="facet-header facet-header--primary"
              sorttype="alpha"
              onClick={handleSortingChange}
            >
              <FacetOrderingLabel
                label={translateKeyword(field, lang)}
                labelStyling="h4"
                correctKey={
                  sorting === 'alpha' ? true : false
                }
                direction={
                  sortDirection === 'desc' ? 'down' : 'up'
                }
              />
            </button>
          </Tip>
          <button
            type="button"
            className="facet-header facet-header--secondary"
            sorttype="count"
            onClick={handleSortingChange}
          >
            <FacetOrderingLabel
              label={translation("search", "pieces", lang)}
              correctKey={
                sorting === 'count' ? true : false
              }
              direction={
                sortDirection === 'desc' ? 'down' : 'up'
              }
            />
          </button>
        </div>

        <div className="facet-checkboxes">
          {facetRenderList &&
            facetRenderList.map((item, idx) => {
              const label = safe(
                `facet_${field}_${item.key}`,
              );
              return (
                <div
                  className="facet-checkbox--container"
                  key={idx}
                  id={`facets-checkbox--container-${idx}`}
                >
                  <IconButton
                    className="listblock--item-key"
                    onClick={() => selectAllButTHis(idx)}
                  >
                    <HighlightOffIcon className="listblock--backward-search-icon" />
                  </IconButton>
                  <div
                    className="facet-checkbox"
                    key={item.key}
                  >
                    <input
                      ref={(el) => {
                        checkBoxes.current[idx] = el;
                      }}
                      type="checkbox"
                      id={label}
                      checked={
                        filters.includes(item.key) ||
                        filters.includes(
                          item.key.toString(10),
                        )
                      }
                      group={field}
                      onChange={onChange}
                      key={item.key}
                      value={item.key}
                      name={label}
                    />
                    <span className="facet-check" />
                    <label
                      htmlFor={label}
                      className="checkbox-label"
                    >
                      <div className="facet-name">
                        {translateKeyword(item.key, lang)}
                      </div>
                      <div className="facet-count">
                        {item.displayedDocCount}
                      </div>
                    </label>
                  </div>
                </div>
              );
            })}
        </div>

        {cachedFacets.length > 5 && (
          <ShowMore
            isOpen={isOpen}
            toggleFunction={toggleOpen}
            moreLabel={`${translation("facets", "show_all", lang)} (${cachedFacets.length})`}
            lessLabel={translation("facets", "show_less", lang)}
          />
        )}
      </Block>
    </Paper>
  );
}

FacetBlock.propTypes = {
  facets: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cachedFacets: PropTypes.arrayOf(PropTypes.shape({}))
    .isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  sorting: PropTypes.string,
};

FacetBlock.defaultProps = {
  sorting: 'count',
  filters: null,
};

export default FacetBlock;
