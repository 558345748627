import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import {
  Paper,
  TextField,
  Button,
} from '@material-ui/core';
import Section from 'components/Section';
import config from 'config';
import './FeedbackForm.css';
import apiService from 'services/apiService';
import {
  reportError,
  clearError,
} from 'actions/errorActions';
import { useSelector } from 'react-redux';
import { translation } from 'utils/translate';

const FeedbackForm = () => {
  const location = useLocation();
  const { from } = location.state || { from: '' };
  const dispatch = useDispatch();
  const [content, setContent] = useState('');
  const [url, setUrl] = useState(from);
  const [email, setEmail] = useState('');
  const [messageSent, setMessageSent] = useState(false);

  const lang = useSelector((store) => store.lang)

  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  const handleSendFeedback = async (event) => {
    event.preventDefault();
    dispatch(clearError());
    try {
      await apiService.sendFeedback({
        content,
        url,
        email,
      });
      setMessageSent(true);
    } catch (err) {
      dispatch(reportError(err));
    }
  };

  return (
    <>
      <Helmet>
        <title>Anna palautetta | {config.site.name}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={2}>
          {messageSent ? (
            <div className="feedback-form">
              <h1 className="feedback-form__title">
                Kiitos palautteestasi!
              </h1>
              <Link to="/">Palaa etusivulle</Link>
            </div>
          ) : (
            <form
              className="feedback-form"
              onSubmit={handleSendFeedback}
            >
              <h1 className="feedback-form__title">
                {translation("feedback", "title", lang)}
              </h1>
              <div>
                  {translation("feedback", "intro_1", lang)}
                  {' '}
                  <a href="mailto:lakitutka@utu.fi">lakitutka@utu.fi</a>.
                  {' '}
                  {translation("feedback", "intro_2", lang)}
              </div>
              <div className="feedback-form__field-wrapper">
                <h5
                  className="feedback-form__label"
                  id="palaute"
                >
                  {translation("feedback", "succeeded", lang)}
                  {' '}*
                </h5>
                <TextField
                  className="feedback-form__field"
                  value={content}
                  onChange={(e) =>
                    setContent(e.target.value)
                  }
                  aria-labelledby="palaute"
                  multiline
                  rows={5}
                  variant="outlined"
                />
              </div>
              <div className="feedback-form__field-wrapper">
                <h5
                  className="feedback-form__label"
                  id="url"
                >
                  {translation("feedback", "site_link", lang)}
                </h5>
                <TextField
                  className="feedback-form__field"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  aria-labelledby="url"
                  placeholder={translation("feedback", "site_link_example", lang)}
                  minRows={5}
                  variant="outlined"
                />
              </div>
              <div className="feedback-form__field-wrapper">
                <h5
                  className="feedback-form__label"
                  id="email"
                >
                  {translation("feedback", "email", lang)}
                </h5>
                <TextField
                  className="feedback-form__field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  aria-labelledby="email"
                  rows={5}
                  variant="outlined"
                />
              </div>
              <div className="feedback-form__button-wrapper">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!content}
                  type="submit"
                >
                  {translation("feedback", "send", lang)}
                </Button>
              </div>
            </form>
          )}
        </Paper>
      </Section>
    </>
  );
};

export default FeedbackForm;
