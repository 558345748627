import React from 'react';
import PropTypes from 'prop-types';
import './ShowMore.css';

const ShowMore = ({
  isOpen,
  toggleFunction,
  moreLabel,
  lessLabel,
}) => (
  <button
    type="button"
    className="show-more-toggler"
    onClick={() => toggleFunction()}
  >
    {isOpen ? `- ${lessLabel}` : `+ ${moreLabel}`}
  </button>
);

ShowMore.propTypes = {
  isOpen: PropTypes.bool,
  toggleFunction: PropTypes.func.isRequired,
  moreLabel: PropTypes.string,
  lessLabel: PropTypes.string,
};

ShowMore.defaultProps = {
  isOpen: false,
  moreLabel: 'näytä kaikki',
  lessLabel: 'näytä vähemmän',
};

export default ShowMore;
