import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from 'actions/langActions';
import {
  Button
} from '@material-ui/core';

import config from 'config';
import './SiteHeader.css';
import { translation } from 'utils/translate';
/* MAIN PAGE HEADER COMPONENT */


const SiteHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const history = useHistory()

  const lang = useSelector((store) => store.lang);

  const triggerChangeLanguage = (langCode) => {
    const url = new URL(window.location);
    url.searchParams.set("lang", langCode);
    history.push(url.search);
    dispatch(changeLanguage(langCode))
  }


  return (
    <div className="site-name-area">
      <div className="site-name-left">
        <div className="site-name">
          <Link to="/" className="link-to-front">
            {config.site.name}
          </Link>
        </div>
        <div className="site-slogan">
          – {translation("slogan", lang)} –
        </div>
      </div>
      <div className="site-name-right">
        <div className="site-name-seperator"></div>
        <div className="site-name-navigator">
          <Link
            to="/#basic-search-term"
            className={
              location.pathname === '/' ? 'selected' : ''
            }
          >
            <b>{translation("haku", lang).toUpperCase()}</b>
          </Link>
          <Link
            to="/tietoja"
            className={
              location.pathname === '/tietoja'
                ? 'selected'
                : ''
            }
          >
            <b>{translation("tietoja", lang).toUpperCase()}</b>
          </Link>
          <Link
            to="/ohjeet"
            className={
              location.pathname === '/ohjeet'
                ? 'selected'
                : ''
            }
          >
            <b>{translation("ohjeet", lang).toUpperCase()}</b>
          </Link>
          <Link
            to="/tutkijalle"
            className={
              location.pathname === '/tutkijalle'
                ? 'selected'
                : ''
            }
          >
            <b>{translation("tutkijalle", lang).toUpperCase()}</b>
          </Link>
          <div className="language-selector"><Button onClick={() => triggerChangeLanguage('fi')} className={lang === 'fi' ? 'selected' : ''}>FI</Button> / <Button onClick={() => triggerChangeLanguage('se')} className={lang === 'se' ? 'selected' : ''}>SV</Button></div>
        </div>
      </div>
    </div>
  );
};

export default SiteHeader;
