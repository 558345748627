import React from 'react';
import PropTypes from 'prop-types';
import './ResultChooser.css';
import { Select, MenuItem } from '@material-ui/core';
import { translateKeyword } from 'utils/translate';
import { useSelector } from 'react-redux';

const ResultChooser = ({
  value,
  options,
  handleChange
}) => {

  const lang = useSelector((store) => store.lang)

  return (
    <Select
      value={value}
      className="result-chooser-selector"
      onChange={handleChange}
    >
      {options.map((opt) => (
        <MenuItem value={opt} key={opt}>
          {translateKeyword(opt, lang)}
        </MenuItem>
      ))}
    </Select>
  )

};

ResultChooser.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default ResultChooser;
