import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  TextField,
  IconButton,
  Paper,
} from '@material-ui/core';
import OffIcon from '@material-ui/icons/HighlightOff';
import ListIcon from '@material-ui/icons/List';
import ChartIcon from '@material-ui/icons/BarChart';
import GetAppIcon from '@material-ui/icons/GetApp';
import TimeLineList from 'components/TimeLine/TimeLineList';
import TimeLineChart from 'components/TimeLine/TimeLineChart';
import DownloadForm from 'components/DownloadForm';
import useToggler from 'hooks/useToggler';
import { Link } from 'react-router-dom';
import './TimeLine.css';
import config from 'config';

import { randomId } from 'utils/commonTools';
import apiService from 'services/apiService';
import { streamFileToUser } from 'utils/fileTools';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

const TimeLine = ({ data, activeDoc }) => {
  const [visualize, setVisualize] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const timelineRef = useRef();

  // download documents state
  const [downloadForm, setDownloadForm] = useState(false);
  const [
    loadingDataFromBackend,
    setLoadingDataFromBackend,
  ] = useState(false);
  const [loadingPercent, setLoadingPercent] = useState(0);
  const [fileDownloadLink, setFileDownloadLink] =
    useState('');
  const [activeDocGroups, setActiveDocGroups] =
    useState(false);

  const lang = useSelector((store) => store.lang)

  // Togglers for TimeLineLists
  const lausuntokierrosToggler = useToggler();
  const lausuntokierrosLausunnotToggler = useToggler();
  const lausuntokierrosMuutToggler = useToggler();
  const hallituksenEsityksetToggler = useToggler();
  const asiantuntijalausunnotToggler = useToggler();
  const valiokuntaToggler = useToggler();
  const eduskunnanVastauksetToggler = useToggler();
  const poytakirjaToggler = useToggler();
  const lakiPaatoksetToggler = useToggler();
  const lakiAloiteToggler = useToggler();
  const kansalaisAloiteToggler = useToggler();

  // scroll to top of the timeline
  const scrollToTop = () => {
    window.scrollTo({
      top: timelineRef.current.offsetTop - 20,
      behavior: 'smooth',
    });
  };

  const closeAllAndScroll = () => {
    lausuntokierrosToggler.off();
    lausuntokierrosLausunnotToggler.off();
    lausuntokierrosMuutToggler.off();
    hallituksenEsityksetToggler.off();
    asiantuntijalausunnotToggler.off();
    valiokuntaToggler.off();
    eduskunnanVastauksetToggler.off();
    poytakirjaToggler.off();
    lakiPaatoksetToggler.off();
    lakiAloiteToggler.off();
    kansalaisAloiteToggler.off();
    scrollToTop();
  };

  const activeData = useMemo(() => {
    if (!searchTerm) {
      return data;
    }
    // filter data with searchTerm
    return (
      Object.entries(data)
        // TMP quick fix for filtering out length data
        .filter((entry) => Array.isArray(entry[1]))
        .reduce(
          (acc, [docGroup, items]) => ({
            ...acc,
            [docGroup]: items.filter((item) =>
              Object.values(item).some(
                (value) =>
                  value &&
                  String(value)
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()),
              ),
            ),
          }),
          {},
        )
    );
  }, [data, searchTerm]);

  const handleDownload = async ({
    metaFormat,
    fileFormats,
  }) => {
      const searchTerms = Object.entries(data)
      .map((searchObject) =>
        config.activeIndices.includes(searchObject[0])
          ? searchObject[1]
              .map((obj) => obj.doc_id)
              .join(',')
          : false,
      )
      .filter((obj) => obj)
      .join(',');

    // show spinner
    setLoadingDataFromBackend(true);

    let jobId = '';
    if (fileFormats) {
      jobId = randomId(8);
    }

    try {
      const result = await apiService.postData(
        'download/all',
        {
          term: searchTerms,
          project: true,
          format: metaFormat,
          content: fileFormats,
          job_id: jobId,
          active_doc_groups: activeDocGroups.join(),
        },
        'text'
      );

      if (result) {
        streamFileToUser({
          data: result,
          format: metaFormat,
          fileName: `project_exported_${new Date()
            .toISOString()
            .slice(0, 10)}`,
        });
      }

      if (fileFormats) {
        const interval = setInterval(async () => {
          const { status } =
            await apiService.checkFileStatus(jobId);

          if (status === 'DONE') {
            clearInterval(interval);
            setFileDownloadLink(jobId);
            setLoadingDataFromBackend(false);
            // eslint-disable-next-line no-restricted-globals
          } else if (!isNaN(status)) {
            setLoadingPercent(parseFloat(status));
          } else {
            clearInterval(interval);
            setLoadingDataFromBackend(false);
            setDownloadForm(false);
            console.log(status);
          }
        }, 3000);
      } else {
        setLoadingDataFromBackend(false);
        setDownloadForm(false);
      }
    } catch (error) {
      setLoadingDataFromBackend(false);
      setDownloadForm(false);
      alert(error);
    }
  };

  const handleDownloadZippedFile = () => {
    setLoadingDataFromBackend(false);
  };

  const setActiveDocGroupsFromTimeline = (docGroups) => {
    if (
      Object.keys(config.docGroups).length !==
      docGroups.length
    ) {
      setActiveDocGroups(docGroups);
    }
  };

  return (
    <Paper className="timeline-wrapper">
      {data.hallituksen_esitykset &&
        data.hallituksen_esitykset[0] &&
        data.hallituksen_esitykset[0].title && (
          <div className="hanke">
            <div className="identifier">
              <Link
                to={`/asiakirjat/hallituksen-esitykset/${data.hallituksen_esitykset[0].doc_id}`}
              >
                {translation("timeline", "project", lang)} 
                {' '}
                {lang === 'se' && data.hallituksen_esitykset[0].tunnus_sv ? 
                  data.hallituksen_esitykset[0].tunnus_sv
                  :
                  data.hallituksen_esitykset[0].tunnus
                }
              </Link>
            </div>
            <h3 className="hanke__title">
              {lang === "se" ?
                <>{data.hallituksen_esitykset[0].title_sv || data.hallituksen_esitykset[0].title}</>
                :
                <>{data.hallituksen_esitykset[0].title || data.hallituksen_esitykset[0].title_sv}</>
              }
            </h3>
          </div>
        )}
      <div className="timeline-header" ref={timelineRef}>
        <h4 className="timeline-header__title">
          {translation("timeline", "filter_documents", lang)}
        </h4>
        <TextField
          id="search-timeline"
          label={translation("timeline", "filter", lang)}
          className="timeline-header__search"
          name="timeline-term"
          value={searchTerm}
          onChange={(event) =>
            setSearchTerm(event.target.value)
          }
        />
        {!!searchTerm && (
          <IconButton
            aria-label="poista rajaus"
            component="span"
            onClick={() => setSearchTerm('')}
          >
            <OffIcon />
          </IconButton>
        )}
        {/* result tools */}
        <div className="timeline-header__button-area">
          {downloadForm ? (
            <Button
              className="button--right"
              disableTouchRipple
              onClick={() => setDownloadForm(!downloadForm)}
            >
              {visualize ? (
                <>
                  <ListIcon className="button-icon" />
                  {translation("timeline", "show_timeline", lang)}
                </>
              ) : (
                <>
                  <ChartIcon className="button-icon" />
                  {translation("timeline", "show_list", lang)}
                </>
              )}
            </Button>
          ) : (
            <>
              <Button
                className="button--right"
                disableTouchRipple
                onClick={() =>
                  setDownloadForm(!downloadForm)
                }
              >
                <>
                  <GetAppIcon className="button-icon" />
                  {translation("timeline", "download", lang)}
                </>
              </Button>
              <Button
                className="button--right"
                disableTouchRipple
                onClick={() => setVisualize(!visualize)}
              >
                {visualize ? (
                  <>
                    <ListIcon className="button-icon" />
                  {translation("timeline", "show_list", lang)}
                  </>
                ) : (
                  <>
                    <ChartIcon className="button-icon" />
                  {translation("timeline", "show_timeline", lang)}
                  </>
                )}
              </Button>
            </>
          )}
        </div>
      </div>

      {downloadForm ? (
        <div className="download-form-wrapper download-form-wrapper--open">
          <DownloadForm
            loadingDataFromBackend={loadingDataFromBackend}
            loadingPercent={loadingPercent}
            fileDownloadLink={fileDownloadLink}
            handleDownload={handleDownload}
            handleDownloadZippedFile={
              handleDownloadZippedFile
            }
          />
        </div>
      ) : visualize ? (
        // Time line view
        <TimeLineChart
          data={activeData}
          scroll={scrollToTop}
          activeDoc={activeDoc}
          setActiveDocGroups={
            setActiveDocGroupsFromTimeline
          }
        />
      ) : (
        // List view
        <Grid
          container
          spacing={4}
          className="timeline-listview"
        >
          {!!activeData.lausuntokierros && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={lausuntokierrosToggler.state}
                toggle={lausuntokierrosToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.lausuntokierros}
                docType="lausuntokierros"
                idField="doc_id"
                labelField="tunnus"
                listFields={[
                  'title',
                  'tila',
                  'asettamis_paiva',
                ]}
              />
            </Grid>
          )}
          {!!activeData.lausuntokierros_lausunnot && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={
                  lausuntokierrosLausunnotToggler.state
                }
                toggle={
                  lausuntokierrosLausunnotToggler.toggle
                }
                onItemClick={closeAllAndScroll}
                data={activeData.lausuntokierros_lausunnot}
                docType="lausuntokierros_lausunnot"
                idField="id"
                labelField="title"
                listFields={['laatija', 'laatimispaiva']}
              />
            </Grid>
          )}
          {!!activeData.lausuntokierros_muut && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={lausuntokierrosMuutToggler.state}
                toggle={lausuntokierrosMuutToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.lausuntokierros_muut}
                docType="lausuntokierros_muut"
                idField="id"
                labelField="title"
                listFields={[
                  'tyyppi',
                  'laatija',
                  'laatimispaiva',
                ]}
              />
            </Grid>
          )}
          {!!activeData.lakialoite && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={lakiAloiteToggler.state}
                toggle={lakiAloiteToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.lakialoite}
                docType="lakialoite"
                idField="doc_id"
                labelField="tunnus"
                listFields={[
                  'title',
                  'asian_tila',
                  'laadittu',
                ]}
              />
            </Grid>
          )}
          {!!activeData.kansalaisaloite && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={kansalaisAloiteToggler.state}
                toggle={kansalaisAloiteToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.kansalaisaloite}
                docType="kansalaisaloite"
                idField="doc_id"
                labelField="tunnus"
                listFields={[
                  'title',
                  'asian_tila',
                  'laadittu',
                ]}
              />
            </Grid>
          )}
          {!!activeData.hallituksen_esitykset && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={hallituksenEsityksetToggler.state}
                toggle={hallituksenEsityksetToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.hallituksen_esitykset}
                docType="hallituksen_esitykset"
                idField="doc_id"
                labelField="tunnus"
                listFields={[
                  'title',
                  'asian_tila',
                  'laadittu',
                ]}
              />
            </Grid>
          )}
          {!!activeData.he_asiantuntijalausunnot && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={asiantuntijalausunnotToggler.state}
                toggle={asiantuntijalausunnotToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.he_asiantuntijalausunnot}
                docType="he_asiantuntijalausunnot"
                idField="doc_id"
                labelField="doc_id"
                listFields={['title', 'valiokunta', 'date']}
              />
            </Grid>
          )}
          {!!activeData.valiokunta_asiakirjat && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={valiokuntaToggler.state}
                toggle={valiokuntaToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.valiokunta_asiakirjat}
                docType="valiokunta_asiakirjat"
                idField="doc_id"
                labelField="tunnus"
                listFields={[
                  'valiokunta',
                  'type',
                  'laadittu',
                ]}
              />
            </Grid>
          )}
          {!!activeData.eduskunnan_vastaukset && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={eduskunnanVastauksetToggler.state}
                toggle={eduskunnanVastauksetToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.eduskunnan_vastaukset}
                docType="eduskunnan_vastaukset"
                idField="doc_id"
                labelField="tunnus"
                listFields={['title', 'laadittu']}
              />
            </Grid>
          )}
          {!!activeData.poytakirja && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={poytakirjaToggler.state}
                toggle={poytakirjaToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.poytakirja}
                docType="poytakirja"
                idField="doc_id"
                labelField="title"
                listFields={[]}
              />
            </Grid>
          )}
          {!!activeData.laws && (
            <Grid item xs={12} sm={6} lg={4}>
              <TimeLineList
                isOpen={lakiPaatoksetToggler.state}
                toggle={lakiPaatoksetToggler.toggle}
                onItemClick={closeAllAndScroll}
                data={activeData.laws}
                docType="laws"
                idField="laki_id"
                labelField="laki_id"
                listFields={[
                  'title',
                  'paatos',
                  'vahvistettu',
                  'voimaantulo',
                ]}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Paper>
  );
};

TimeLine.propTypes = {
  data: PropTypes.shape({
    hallituksen_esitykset: PropTypes.arrayOf(
      PropTypes.shape({
        tunnus: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
  activeDoc: PropTypes.string.isRequired,
};

export default TimeLine;
