/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Identifier from 'components/Identifier';
import Tags from 'components/Tags';
import DocInfo from 'components/DocInfo';
import { Helmet } from 'react-helmet';
import config from 'config';
import './SearchResults.css';
import {
  mapIndiceToDocGroup,
  tunnus2DocId,
  tunnusWithVp2DocId,
} from 'utils/commonTools';
import { translateKeyword, translation } from 'utils/translate';
import { useSelector } from 'react-redux';

/* SEARCH RESULTS -COMPONENT */

const SearchResults = ({
  hits,
  docGroup,
  highlightTerm,
  page,
  size,
  searchTerm,
}) => {
  const lang = useSelector((store) => store.lang)

  const getUrl = (hit) => {
    let group = docGroup.replace('_', '-');
    if (docGroup === 'all')
      group = mapIndiceToDocGroup(hit._index);
    if (hit._id.startsWith('EDK'))
      group = 'he-asiantuntijalausunnot';
    if (hit._index === 'laki_lausuntokierros_asiakirjat')
      group = 'lausuntokierros_asiakirjat'

    return highlightTerm
      ? `/asiakirjat/${group}/${hit._id}?hilight=${highlightTerm}&lang=${lang}`
      : `/asiakirjat/${group}/${hit._id}?lang=${lang}`;
  };

  const hitIdIsSearchterm = (docId) =>
    docId === tunnus2DocId(searchTerm) || docId === tunnusWithVp2DocId(searchTerm)
      ? 'result-highlighted'
      : '';


  const setTitle = (hit) => {
    let title = null

    if (hit._source.title) {
      title = hit._source.title
    } else if (hit._source.title_sv) {
      title = hit._source.title_sv
    }

    if (hit.highlight) {
      if (hit.highlight.title) {
        title = hit.highlight.title[0]
      } else if (hit.highlight.title_sv) {
        title = hit.highlight.title_sv[0]
      }
    }

    if (!title)
    {
      title = translation("missing_title", lang)
    }

    if ('formatTitle' in config.docGroups[docGroup]) {
      return config.docGroups[docGroup].formatTitle(
        hit._source,
        hit._index,
        title,
        lang
      )
    }

    return title
  }

  return (
    <>
      <Helmet>
        <title>
          {searchTerm ? `${config.docGroups[docGroup].name} "${searchTerm.replace('"', '')}" | ${config.site.name}`
            : `${config.docGroups[docGroup].name} | ${config.site.name}`}
        </title>
      </Helmet>
      {hits.map((hit, idx) => (
        <div
          key={hit._id}
          className={`result + ${hitIdIsSearchterm(
            hit._id,
          )}`}
        >
          <div className="result-index">
            {(page - 1) * size + idx + 1}.
          </div>
          <div className="result-status">
            {hit._source.asian_tila || hit._source.tila || "asiakirjat.tyyppi" in hit._source ? translateKeyword(hit._source["asiakirjat.tyyppi"]) : null}
          </div>
          {hit._source.tunnus && (
            <Identifier>{hit._source.tunnus}</Identifier>
          )}
          {hit._source.tunnus_sv && (
            <Identifier>{hit._source.tunnus_sv}</Identifier>
          )}
          <Link
            className="result-title-link"
            to={getUrl(hit)}
          >

            <h4
              className="result-title"
              dangerouslySetInnerHTML={{
                __html: setTitle(hit)
              }}
            />
          </Link>

          <DocInfo
            className="doc-info--results"
            fields={config.docGroups[
              docGroup
            ].infoFields.map((field) => [
              field,
              hit._source[field],
            ])}
            key={idx}
          />

          <div
            className="doc-info"
            dangerouslySetInnerHTML={{
              __html:
                'formatSecondaryInfoField' in
                  config.docGroups[docGroup]
                  ? config.docGroups[
                    docGroup
                  ].formatSecondaryInfoField(
                    hit._source,
                    hit._index,
                    lang
                  )
                  : null,
            }}
          />

          {/* show possible semantic hits from content */}
          <div
            key={'highlights-semantic--' + idx}
            className={'result-snippets ' + searchTerm}
          >
            {hit?.inner_hits?.content_passages?.hits?.hits && (
              <>
                <span className="doc-info--lower" key={'highlight-semantic--' + idx}>
                  {translation("results", "hit", lang)}:{' '}
                </span>
                <em><i>{hit.inner_hits.content_passages.hits.hits.map(e => (<>{e.fields.content_passages[0].text[0]}</>))}</i></em>
              </>
            )}

            {hit?.inner_hits?.content_vector?.hits?.hits && (
              <>
                <span className="doc-info--lower" key={'highlight-inner-hits--' + idx}>
                  {translation("results", "hit", lang)}:{' '}
                </span>
                <em><i>{hit.inner_hits.content_vector.hits.hits.map(e => (<>{e.fields.content_vector[0].text[0]}</>))}</i></em>
              </>
            )}
          </div>

          {hit.highlight && (
            <div
              key={'highlights--' + idx}
              className={'result-snippets ' + searchTerm}
            >
              <span className="doc-info--lower" key={'highlight-info--' + idx}>
                {translation("results", "hit", lang)}:{' '}
              </span>
              {Object.entries(hit.highlight).map(
                (entry) => {
                  /*
                  const entry = {
                    tilte: ['keyword in title'],
                    content: ['Hello keyword world', 'second keyword', ...],
                    }
                  */
                  return entry[1].map((hl, eIdx) =>
                    entry[0].includes('content', 'kuvaus', 'tiivistelma', 'tavoitteet', 'tilanne', 'vaikutukset') ? (
                      <span
                        // eslint-disable-next-line react/no-array-index-key
                        key={entry[0] + eIdx}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: `<i>...${hl}...</i> `,
                        }}
                      />
                    ) : entry[0] === 'puheenvuorot' ? (
                      <span
                        // eslint-disable-next-line react/no-array-index-key
                        key={entry[0] + eIdx}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: `${hl}`,
                        }}
                      />
                    ) : eIdx === 0 &&
                      entry[0] === 'laws' ? (
                      <React.Fragment key={"highlight-law-info-fragment--" + idx}>
                        <br />
                        <span className="doc-info--lower" key={"highlight-law-info--" + idx}>
                          Hakuosuma laissa:{' '}
                        </span>
                        <span
                          // eslint-disable-next-line react/no-array-index-key
                          key={entry[0] + eIdx}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: hl + '<br>',
                          }}
                        />
                      </React.Fragment>
                    ) : null
                  );
                },
              )}
            </div>
          )}

          {config.docGroups[docGroup].tagFields &&
            config.docGroups[docGroup].tagFields[lang].map(
              (field) =>
                Array.isArray(hit._source[field]) ? (
                  <Tags
                    className="tags--results"
                    title={translation("results", "asiasanat", lang)}
                    key={field}
                    highlights={hit.highlight && hit.highlight[field] ? hit.highlight[field].map(((hl) => hl.replace(/<[^>]*>?/gm, ''))) : []}
                    tagList={hit._source[field]}
                  />
                ) : null,
            )}
        </div>
      ))}
    </>
  );
};

SearchResults.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  docGroup: PropTypes.string.isRequired,
  highlightTerm: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  searchTerm: PropTypes.string.isRequired,
};

export default SearchResults;
