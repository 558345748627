/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  HorizontalBarSeries,
  Hint,
} from 'react-vis';
import '../../../../node_modules/react-vis/dist/style.css';
import './ResultCharts.css';
import { Button, Paper } from '@material-ui/core';
import config from 'config';
import {
  colorVariant,
  colorVariantTp,
} from 'utils/colorTools';
import { translateKeyword, translation } from 'utils/translate';
import texts from 'texts';
import Tip from 'components/Tip';

/* SEARCH RESULT CHARTS -COMPONENT */

const ResultCharts = ({
  docGroup,
  dataset,
  handleFacetChange,
  cache,
}) => {
  const facetConstants = useSelector(
    (store) => store.facetConstants,
  );

  const lang = useSelector(
    (store) => store.lang,
  );

  const [animation, setAnimation] = useState(true);
  const [yearCompareTotal, setYearCompareTotal] = useState(
    false,
  );
  const [
    yearComparePreFilter,
    setYearComparePreFilter,
  ] = useState(false);
  const [hintValue, setHintValue] = useState({});
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  const hasPrefilters =
    !!cache && Object.keys(cache).length > 0;

  const yearsTotal = facetConstants[docGroup].year;

  const chartFields = useMemo(
    () =>
      config.docGroups[docGroup].facetFields.filter(
        (f) => f !== 'year',
      ),
    [docGroup],
  );

  // Use effect hook to keep track of mouse position for tooltips
  useEffect(() => {
    const setFromEvent = (e) =>
      setMousePos({ x: e.layerX, y: e.layerY });
    window.addEventListener('mousemove', setFromEvent);
    return () =>
      window.removeEventListener('mousemove', setFromEvent);
  }, []);

  // Reorganize bucket data for react-vis charts
  const datas = useMemo(
    () =>
      Object.entries(dataset).reduce(
        (accum, [field, values]) => ({
          ...accum,
          [field]: values.buckets
            .map((bucket) => ({
              id: field,
              x: bucket.doc_count,
              y: translateKeyword(bucket.key, lang),
            }))
            .reverse(),
        }),
        {},
      ),
    [dataset, lang],
  );

  // Invert x & y for horizontal barchart "Years" to new array
  const years = useMemo(
    () =>
      Object.keys(yearsTotal).map((year) => {
        const vals = datas.year.find(
          (obj) => obj.y == year,
        );
        return {
          id: 'year',
          x: year,
          y: vals ? vals.x : 0,
        };
      }),
    [datas, yearsTotal],
  );

  // Calculate data for items not in results by year
  const yearsRest = useMemo(
    () =>
      Object.keys(yearsTotal).map((year) => {
        const vals = years.find((obj) => obj.x == year);
        return {
          id: 'yearTotal',
          x: year,
          y: yearsTotal[year] - vals.y,
        };
      }),
    [years, yearsTotal],
  );

  // Calculate prefiltered data for items not in results by year
  const yearsRestPreFilter = useMemo(
    () =>
      hasPrefilters
        ? Object.keys(yearsTotal).map((year) => {
          const vals = years.find((obj) => obj.x == year);
          const valsPre = cache.year.buckets.find(
            (obj) => obj.key == year,
          );
          return {
            id: 'yearTotalPrefilter',
            x: year,
            y: valsPre ? valsPre.doc_count - vals.y : 0,
          };
        })
        : null,
    [cache, yearsTotal, years, hasPrefilters],
  );

  // Function for generating hint object for year compare, eg. { 2016: 102/153 (66,7%) }
  const generateYearHint = (v) => {
    const year = v.x;
    if (yearCompareTotal && yearsTotal[year] > 0) {
      const val = v.y0 || (v.id === 'year' ? v.y : 0);
      const valT = yearsTotal[year];
      return {
        [year]: `${val}/${valT} (${(
          (val / valT) *
          100
        ).toFixed(1)}%)`,
      };
    }
    return { [year]: v.y };
  };

  return (
    <Paper elevation={2} className="result-charts">
      {/* Vertical barchart for years  */}
      <div className="chart-wrapper">
        <div className="chart-title-wrapper">
          <Tip title={translation("visual", "year", lang)}>
            <h3 className="chart-title tippable">{translateKeyword("year", lang)}</h3>
          </Tip>
        </div>
        <Tip title={translation("visual", "compare_all_tooltip", lang)}>
          <Button
            className="button--chart"
            style={
              !yearCompareTotal ? { color: '#6a7a89' } : {}
            }
            disableTouchRipple
            onClick={() => {
              setYearCompareTotal(!yearCompareTotal);
              setYearComparePreFilter(false);
            }}
          >
            {yearCompareTotal
              ? `☒ ${translation("visual", "compare_all", lang)}`
              : `☐ ${translation("visual", "compare_all", lang)}`}
          </Button>
        </Tip>
        {hasPrefilters && (
          <Tip title={translation("visual", "compare_prefilter_tooltip", lang)}>
            <Button
              className="button--chart"
              style={
                !yearComparePreFilter
                  ? { color: '#6a7a89' }
                  : {}
              }
              disableTouchRipple
              onClick={() => {
                setYearComparePreFilter(
                  !yearComparePreFilter,
                );
                setYearCompareTotal(false);
              }}
            >
              {yearComparePreFilter
                ? `☒ ${translation("visual", "compare_prefilter", lang)}`
                : `☐ ${translation("visual", "compare_prefilter", lang)}`}
            </Button>
          </Tip>
        )}
        <XYPlot
          margin={{ left: 50, right: 10 }}
          width={1136}
          height={350}
          xType="ordinal"
          stackBy="y"
        >
          <HorizontalGridLines />
          <VerticalBarSeries
            animation={animation}
            data={years}
            onValueClick={(datapoint) => {
              handleFacetChange('year', datapoint.x);
            }}
            onValueMouseOver={(v) => {
              setHintValue({
                yearVer: generateYearHint(v),
              });
              setAnimation(false);
            }}
            onValueMouseOut={() => {
              setHintValue({});
              setAnimation(true);
            }}
            onSeriesMouseOut={() => {
              setHintValue({});
              setAnimation(true);
            }}
            color={colorVariant(0)}
          />
          {yearCompareTotal && (
            <VerticalBarSeries
              data={yearsRest}
              onValueClick={(datapoint) =>
                handleFacetChange('year', datapoint.x)
              }
              onValueMouseOver={(v) =>
                setHintValue({
                  yearVer: generateYearHint(v),
                })
              }
              onValueMouseOut={() => {
                setAnimation(true);
                setHintValue({});
              }}
              onSeriesMouseOut={() => {
                setAnimation(true);
                setHintValue({});
              }}
              color={colorVariantTp(0)}
            />
          )}
          {yearComparePreFilter && (
            <VerticalBarSeries
              data={yearsRestPreFilter}
              onValueClick={(datapoint) =>
                handleFacetChange('year', datapoint.x)
              }
              onValueMouseOver={(v) =>
                setHintValue({
                  yearVer: generateYearHint(v),
                })
              }
              onValueMouseOut={() => {
                setAnimation(true);
                setHintValue({});
              }}
              onSeriesMouseOut={() => {
                setAnimation(true);
                setHintValue({});
              }}
              color={colorVariantTp(0)}
            />
          )}
          <XAxis
            tickLabelAngle={-45}
          />
          <YAxis
            tickFormat={(val) =>
              Math.round(val) === val ? val : ''
            }
          />
          {hintValue.yearVer && mousePos.x > 50 && (
            <Hint
              x={mousePos.x - 50}
              y={mousePos.y - 20}
              value={hintValue.yearVer}
            />
          )}
        </XYPlot>
      </div>

      {/* Horizontal barcharts  */}
      {chartFields.map(
        (field, idx) =>
          datas[field] && (
            <div className="chart-wrapper" key={field}>
              <div className="chart-title-wrapper">
                {texts.tooltip[field] ? (
                  <Tip title={texts.tooltip[field]}>
                    <h3 className="chart-title tippable">
                      {translateKeyword(field, lang)}
                    </h3>
                  </Tip>
                ) : (
                  <h3 className="chart-title">
                    {translateKeyword(field, lang)}
                  </h3>
                )}
              </div>

              <XYPlot
                margin={{ left: 200, right: 50 }}
                width={564}
                height={600}
                yType="ordinal"
              >
                <VerticalGridLines />
                <HorizontalBarSeries
                  data={datas[field]}
                  onValueClick={(datapoint) => {
                    handleFacetChange(
                      datapoint.id,
                      datapoint.y,
                    );
                  }}
                  onValueMouseOver={(v) => {
                    if (
                      !hintValue[field] ||
                      hintValue[field] !== v
                    ) {
                      setHintValue({ [field]: v });
                    }
                  }}
                  onValueMouseOut={() => setHintValue({})}
                  onSeriesMouseOut={() => setHintValue({})}
                  color={colorVariant(idx + 1)}
                />
                <XAxis
                  tickFormat={(val) =>
                    Math.round(val) === val ? val : ''
                  }
                />
                <YAxis />
                {hintValue[field] && (
                  <Hint
                    x={mousePos.x - 50}
                    y={mousePos.y - 50}
                    value={{
                      [hintValue[field].y]:
                        hintValue[field].x,
                    }}
                  />
                )}
              </XYPlot>
            </div>
          ),
      )}
    </Paper>
  );
};

ResultCharts.propTypes = {
  docGroup: PropTypes.string.isRequired,
  dataset: PropTypes.shape({}).isRequired,
  handleFacetChange: PropTypes.func.isRequired,
  cache: PropTypes.shape({
    year: PropTypes.shape({
      buckets: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
};

export default ResultCharts;
