import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useLocation, Link } from 'react-router-dom';
import Section from 'components/Section';
import SearchForm from 'components/SearchForm';
import config from 'config';
import './Home.css';
import {
  Hidden,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import Tip from 'components/Tip';
import {
  showInCurrentEnvironment,
  scrollToTop,
} from 'utils/commonTools';

import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import { translation } from 'utils/translate';

const Home = () => {
  const { docGroup } = useParams();
  const { hash } = useLocation();

  const lang = useSelector((store) => store.lang);

  const [activeForm, setActiveForm] = useState(
    docGroup || config.defaultDocGroup,
  );

  const hashLinkScroll = () => {
    const { hash } = window.location;
    if (hash !== '') {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  };

  useEffect(() => {
    const fab = document.getElementsByClassName(
      'fab-navigation',
    )[0];

    const myScrollFunc = () => {
      if (window.scrollY >= 800) {
        fab.classList.remove('fab-hide');
      } else {
        fab.classList.add('fab-hide');
      }
    };

    window.addEventListener('scroll', myScrollFunc);

    hashLinkScroll();
  }, [hash]);

  return (
    <>
      <Section className="section--info-area">
        <div className="intro-info">
          {translation("intro1", lang)}
          <div className="info-highlight">
            <Link to="/ohjeet">
              <VideoIcon className="info-highlight__icon" />
              {translation("video_instruction", lang)}
            </Link>
          </div>
          {translation("intro2", lang)}{' '}
          <Link to="/palaute">
            {translation("palaute", lang)}
          </Link>
          {lang === 'fi' && 
            <div className="info-highlight-new">
              <div className="left-col">
                <img
                  alt="Lakita"
                  src="/lakita-small.png"
                 />
              </div>
              <div className="right-col">
                {translation("new_lakitutka_1", lang)}!<br/>
                {translation("new_lakitutka_2", lang)}<br/>
                {translation("new_lakitutka_3", lang)}{" "}
                <Link to={{ pathname: "https://blogit.utu.fi/lakitutka/2024/04/25/lakitutka-uudistuu-inspiraationakyma-tutkimusnakyman-rinnalle/" }}
                  rel="noopener noreferrer"
                  target="_blank">
                  {translation("new_lakitutka_4", lang)}
                </Link>.
              </div>
            </div>
            }
        </div>
      </Section>

      {/* Mobile selector */}
      <Hidden smUp>
        <Section
          className="section--select-area section--select-area--mobile"
          role="navigation"
        >
          <h3
            className="document-type-mobile-select-label"
            id="mobiselect-label"
          >
            {translation("mobile_document_group", lang)}
          </h3>
          <FormControl
            variant="filled"
            fullWidth
            className="document-type-mobile-select"
          >
            <Select
              labelId="mobiselect-label"
              fullWidth
              value={activeForm}
              onChange={(event) =>
                setActiveForm(event.target.value)
              }
            >
              {Object.entries(config.docGroups).map(
                ([key, value]) =>
                  value.searchable && (
                    <MenuItem value={key} key={key}>
                      {translation("doc_groups", key, "name", lang)}
                    </MenuItem>
                  ),
              )}
            </Select>
          </FormControl>
        </Section>
      </Hidden>
      <Hidden xsDown>
        <Section
          className="section--select-area"
          role="navigation"
        >
          {Object.entries(config.docGroups)
            .filter((item) =>
              showInCurrentEnvironment(item[1]),
            )
            .map(
              ([key, value]) =>
                value.searchable && (
                  <Tip
                    title={
                      <>
                        {translation("doc_groups", key, "text", lang)}
                        <br />
                        <br />
                        {translation("doc_groups", key, "source", lang)}
                      </>
                    }
                    key={key}
                  >
                    <button
                      type="button"
                      className={
                        key === activeForm
                          ? 'document-type-select document-type-select--active'
                          : 'document-type-select'
                      }
                      onClick={() => setActiveForm(key)}
                    >
                      {translation("doc_groups", key, "name", lang)}
                    </button>
                  </Tip>
                ),
            )}{' '}
        </Section>
      </Hidden>

      <Section className="section--content" role="main">
        <SearchForm docGroup={activeForm} />

        <Fab
          variant="extended"
          size="small"
          color="primary"
          aria-label="add"
          className="fab-navigation fab-hide"
          onClick={() => scrollToTop()}
        >
          <NavigationIcon />
        </Fab>
      </Section>
    </>
  );
};

export default Home;
