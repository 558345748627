import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Paper } from '@material-ui/core';
import './Siblings.css';
import config from 'config';
import { formatDate } from 'utils/commonTools';

const { docGroups } = config;

const Siblings = ({ data, docGroup, docID }) =>
  data.length > 0 && (
    <Paper elevation={2}>
      <div className="block block--siblings">
        <h3 className="block--title">
          {docGroups[docGroup]?.name || docGroup}
        </h3>
        {data.map((item) => {
          const isActive = docID === `${item.id}`;
          return (
            <div className="sibling" key={item.id}>
              <div className="sibling__date">
                {formatDate(item.date)}
                {item.closingDate
                  ? ` - ${formatDate(item.closingDate)}`
                  : null}
              </div>
              {item.link.startsWith('http') ? (
                <>
                  <a
                    className={
                      isActive
                        ? 'sibling__link sibling__link--active'
                        : 'sibling__link'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.link}
                  >
                    {item.title}
                  </a>
                </>
              ) : (
                <Link
                  className={
                    isActive
                      ? 'sibling__link sibling__link--active'
                      : 'sibling__link'
                  }
                  to={item.link}
                >
                  {item.title}
                </Link>
              )}
            </div>
          );
        })}
      </div>
    </Paper>
  );

Siblings.propTypes = {
  docGroup: PropTypes.string.isRequired,
  docID: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Siblings;
