import React from 'react';
import VideoIcon from '@material-ui/icons/OndemandVideo';
import { Link } from 'react-router-dom';

const info = {
  intro: (
    <>
      Lakitutka kokoaa yhteen lainvalmistelun eri vaiheisiin
      liittyvät julkiset asiakirjat. Voit tutkia
      lakihankkeiden etenemistä hallituksen esitysten,
      annettujen lausuntojen, mietintöjen ja
      eduskuntakeskusteluiden kautta. Pääset tarkastelemaan
      yksittäisen hankkeen elinkaarta avaamalla minkä
      tahansa siihen liittyvän dokumentin.
      <div className="info-highlight">
        <Link to="/ohjeet">
          <VideoIcon className="info-highlight__icon" />
          Ohjeet ja videoesimerkit Lakitutkan käyttöön
        </Link>
      </div>
      Lakitutka on Turun yliopistossa kehitetty avoin palvelu.
      Lakitutkaa kehitetään jatkuvasti käytettävyyden ja
      materiaalin osalta.{' '}
      <Link to="/palaute">
        Otamme mielellämme vastaan palautetta
      </Link>
      .
    </>
  ),
  machineread: 'Teksti voi olla koneellisesti luettu.',
  machineread_older_text: 'Teksti on koneellisesti luettu paperimuotoisesta asiakirjasta skannatusta sähköisestä kopiosta. Teksti sisältää siksi käsittelyprosessissa syntyneitä virheitä.',
  lausunnot_no_content:
    'Lausuntovaiheen asiakirjat tulevat Lakitutkaan valtioneuvoston Hankeikkunan rajapinnan kautta. Tästä asiakirjasta löytyy toistaiseksi ainoastaan metatiedot, sillä dokumenttia ei ole lisätty valtioneuvoston Hankeikkunaan.',
  feedback: (
    <>
      Jos toivot vastausta palautteeseesi tai haluat
      aloittaa keskustelun, niin ota meihin suoraan yhteyttä
      sähköpostilla{' '}
      <a href="mailto:lakitutka@utu.fi">lakitutka@utu.fi</a>
      . Ethän kirjoita henkilökohtaisia tietojasi alla
      olevaan lomakkeeseen.
    </>
  ),
};

const tooltip = {
  basic:
    'Perushaku: Hae hakusanalla. Katkaisumerkki on *, ja se toimii sanan alussa ja lopussa. Voit kirjoittaa hakukenttään useita hakusanoja AND, OR ja NOT - toimintoja käyttäen. Jos haet hallituksen esityksen numerolla, kirjoita se kenttään muodossa “HE 100/2017”.',
  proximity:
    'Proksimiteettihaku: Jos haluat hakea kahdella yhdessä esiintyvällä hakusanalla, voit tehdä sen proksimiteettihaulla. Määritä hakusanat ja sanojen suurin mahdollinen etäisyys toisistaan. Muistathan katkaisumerkit *.',
  prefilters:
    'Esirajaus-toiminnolla voit jo alkuvaiheessa rajata hakutuloksia. Käyttäessäsi esirajaus-toimintoa hakutuloksina ovat vain ne hakusanaosumat, jotka täyttävät myös esirajauksen kriteerit. ',
  year: 'Niiden dokumenttien vuosittainen määrä, joissa hakutermi esiintyy. Joka 4. vuoden piikki johtuu hallituksen esitysten kohdalla siitä, että hallituskauden viimeisenä vuonna annetaan usein enemmän esityksiä kuin muina vuosina.',
  asiasanat:
    'Dokumentteihin liittyvät asiasanat eduskunnan tietokannan mukaan.',
  talousarviot:
    'Valtion eli talousarvio on vuosittainen valtion taloutta ja varainhoitoa koskeva suunnitelma, jossa päätetään budjettitalouden meno- ja tuloarvioista.',
  eduskunnan_kirjelma:
    'Eduskunnan kirjelmä on asiakirja, jolla eduskunta ilmoittaa hallitukselle tai valtioneuvostolle päätöksensä tai kannanottonsa muussa kuin hallituksen lakiesitystä koskevassa asiassa. Eduskunnan kirjelmällä ilmoitetaan päätöksestä tai kannanotosta, joka koskee esim. valtion talousarvioita, pääministerin valintaa tai valtiopäivien keskeyttämistä/päättämistä.',
  asetukset:
    'Valtioneuvoston asetukset sekä tärkeimmät ministeriöiden antamat asetukset',
  compare_all:
    'Näytä hakutuloksesi suhteessa kaikkiin saman kategorian asiakirjoihin.',
  compare_prefilter:
    'Näytä hakutuloksesi suhteessa kaikkiin esirajattuihin asiakirjoihin.',
  /* Timeline */
  timeline: {
    lausuntokierros:
      'Valmistelevan ministeriön ilmoittama lakihankkeen aloitusajankohta. Tämä voi olla esimerkiksi työryhmän toimikauden tai virkatyövalmistelun aloituspäivämäärä.',
    valiokunta_asiakirjat:
      'Valiokuntien lausunnot ja mietinnöt. Valiokunnan mietintö syntyy valiokunnan käsittelyn pohjalta, ja sen perusteella eduskunnan täysistunto päättää asiasta. Valiokunta voi pyytää valiokuntakäsittelyn vaiheessa lausuntoa toiselta valiokunnalta.',
    laws: 'Lakipäätös -kohdassa näet hankkeen ratkaisun. Siitä ilmenee, onko hallituksen tekemä lakiehdotus eduskunnassa esimerkiksi hyväksytty sellaisenaan, vai onko sitä muutettu ennen hyväksymistä. Esitys on voitu myös hylätä kokonaan tai osittain.',
    hallituksen_esitykset:
      'Hallituksen esityksellä lainsäädäntöasia laitetaan vireille eduskunnassa. Hallituksen esitykset valmistellaan ministeriöissä, ja suurin osa lainsäädäntöasioista eduskunnassa on lähtöisin hallituksen esityksestä.',
    lausuntokierros_lausunnot:
      'Hankkeen valmistelun jälkeen luonnoksesta hallituksen esitykseksi pyydetään kirjallisia lausuntoja valmistelevan ministeriön ulkopuolisilta tahoilta. Näitä voivat olla esimerkiksi muut ministeriöt, yhdistykset, järjestöt, viranomaiset ja kansalaiset.',
    lausuntokierros_muut:
      'Muut lausuntokierrokseen liittyvät asiakirjat voivat olla tyypiltään esimerkiksi lausuntopyyntöjä, kirjeitä, luonnoksia, liitteitä tai yhteenvetoja. Kirjauskäytännöt vaihtelevat, joten tyypit ovat vain suuntaa-antavia',
    he_asiantuntijalausunnot:
      'Valiokunnissa kuultujen asiantuntijoiden lausunnot. Asiantuntijalausunnot annetaan valiokunnalle valiokunnan pyynnöstä, kun taas lausuntokierroksen lausunnot aikaisemmassa vaiheessa ministeriölle.',
    hankkeen_asettaminen:
      'Hankkeen aloitus Hankeikkunan tietojen mukaan.',
    eduskunnan_vastaukset:
      'Eduskunta ilmoittaa valtioneuvostolle päätöksensä käsiteltävänä olevasta lainsäädäntöasiasta eduskunnan vastauksella.',
    poytakirja:
      'Eduskuntakeskusteluihin on taltioitu kirjallisessa muodossa hallituksen esityksistä käydyt eduskunnan täysistunnon keskustelut.',
    piilotetut:
      'Aikajanalla näkyvät vain ne asiakirjat, joille on saatavilla päivämäärä rajapintojen kautta. Päivämäärättömät asiakirjat löytyvät kuitenkin listanäkymästä, joka aukeaa oikean yläkulman NÄYTÄ LISTOINA -painikkeesta.',
  },
  /* Facet tooltips */
  facet: {
    "asiakirjat.tyyppi": 'Jos asiakirjatyyppiä ei ole valittu, niin näkymään listataan hankkeen metatiedot.',
    year: 'Vuosi diaarinumeron mukaan.',
    asiasanat:
      'Dokumentteihin liittyvät asiasanat eduskunnan tietokannan mukaan.',
    hallitus_hk: 'Hallitus hallituskausien mukaan',
  },
};

const contentDescription = {
  intro: 'Lakitutkasta löytyvät dokumentit',
  lausuntokierros: {
    text: (
      <>
        <span className="info-emphasis">
          Lainsäädäntöhankkeen tiedot ja hankkeisiin
          liittyvät valmisteluasiakirjat
        </span>
        , kuten hallituksen esityksen luonnosversiot,
        lausuntokierroksen lausuntopyynnöt ja lausunnot sekä
        mahdolliset selvitykset, löytyvät vuodesta 1995
        lähtien. Hankkeet ja niihin liittyvät asiakirjat
        löytyvät ainoastaan siltä osin, kuin ne on julkaistu
        Valtioneuvoston Hankeikkunassa. Varsinkin vuosilta
        1995-2000 asiakirjoja löytyy harvakseltaan.
      </>
    ),
    source: 'Lähteenä valtioneuvoston Hankeikkuna.',
  },
  hallituksen_esitykset: {
    text: (
      <>
        <span className="info-emphasis">
          Hallituksen esitykset
        </span>{' '}
        löytyvät vuodesta 1991 alkaen. Uudet hallituksen
        esitykset ja niihin linkittyvät dokumentit
        päivitetään Lakitutkaan sitä mukaa kun ne tulevat
        saataville. Myös vanhempaa sisältöä tullaan
        lisäämään palveluun tulevaisuudessa.
      </>
    ),
    source:
      'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
    update_policy:
      'Uusimmat dokumentit pyritään lisäämään hakukoneeseen päivittäin.',
  },
  valiokunta_asiakirjat: {
    text: (
      <>
        <span className="info-emphasis">
          Valiokunta-asiakirjat
        </span>{' '}
        eli valiokuntien lasunnot ja mietinnöt löytyvät
        vuodesta 1991 alkaen. Tämän välilehden alta löytyvät
        myös valiokuntien asiantuntijalausunnot, jotka ovat
        saatavissa vuodesta 2015 alkaen.
      </>
    ),
    source:
      'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
  },
  poytakirja: {
    text: (
      <>
        <span className="info-emphasis">
          Eduskuntakeskustelujen pöytäkirjat
        </span>{' '}
        löytyvät vuodesta 2015 alkaen. Lakitutkassa on
        ainoastaan pöytäkirjat keskusteluista, jotka
        liittyvät valmisteltavaan lakiin.
      </>
    ),
    source:
      'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
  },
  lakialoite: {
    text: (
      <>
        <span className="info-emphasis">
          Kansanedustajien lakialoitteet
        </span>{' '}
        löytyvät vuodesta 2015 alkaen.
        Lisäksi Lakitutkasta löytyvät kaikki eduskunnalle luovutetut <span className="info-emphasis">
          kansalaisaloitteet
        </span>.{' '}
      </>
    ),
    source:
      'Lähteenä Eduskunnan avoimen datan verkkopalvelu.',
  },
  all: {
    text: (
      <>
        <span className="info-emphasis">
          Kaikki asiakirjat
        </span>{' '}
      </>
    ),
    source:
      'Lähteenä Eduskunnan avoimen datan verkkopalvelu sekä valtioneuvoston hankeikkuna.',
  },
};

export default {
  info,
  tooltip,
  contentDescription,
};
