import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Section from 'components/Section';
import './Footer.css';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

const Footer = () => {
  const lang = useSelector(
    (store) => store.lang,
  );

  return (
    <Section className="section--footer">
      <Grid
        container
        spacing={4}
        className="footer-container"
      >
        <Grid item xs={12} sm={6} md={3}>
          <a
            href="https://www.utu.fi"
            target="_BLANK"
            rel="noopener noreferrer"
          >
            <img
              alt="Turun yliopiston logo"
              src="/utu-logo.png"
            />
          </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          TURUN YLIOPISTO
          <br />
          Oikeustieteellinen tiedekunta
          <br />
          Tutkimuksen IT
        </Grid>
        <Grid item sm={12} md={3}>
          <div className="footer__link-wrapper">
            <Link to="/ohjeet" className="footer__link">
              {translation("footer", "ohjeet", lang)}
            </Link>
            <Link to="/palaute" className="footer__link">
              {translation("footer", "palaute", lang)}
            </Link>
            <Link to="/tietosuoja" className="footer__link">
              {translation("footer", "tietosuoja", lang)}
            </Link>
            <Link
              to="/saavutettavuus"
              className="footer__link"
            >
              {translation("footer", "saavutettavuus", lang)}
            </Link>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <a
            href="https://blogit.utu.fi/lakitutka/"
            target="_BLANK"
            rel="noopener noreferrer"
            className="blog__link"
          >
            <img
              className="blog__logo"
              alt="Blog"
              src="/blog-solid.svg"
            />
          </a>
          <a
            href="https://www.twitter.com/lakitutka"
            target="_BLANK"
            rel="noopener noreferrer"
            className="twitter__link"
          >
            <img
              className="twitter__logo"
              alt="Twitter"
              src="/x-logo-black.png"
            />
          </a>
        </Grid>
      </Grid>
    </Section>
  )
};

export default Footer;
