import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '0.8rem',
    padding: '1rem',
    background: '#444',
  },
});

/* Mui Tooltip wrapper component */
const Tip = ({ children, title, ...props }) => {
  const classes = useStyles();
  return !title ? (
    children
  ) : (
    <Tooltip
      classes={classes}
      enterDelay={300}
      placement="top"
      arrow
      title={title}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {children}
    </Tooltip>
  );
};

Tip.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
};

Tip.defaultProps = {
  title: null,
};

export default Tip;
