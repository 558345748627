import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Divider, Paper } from '@material-ui/core';

const PrivacyPolicy = () => {
  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Helmet>
        <title>
          Tietosuojaseloste | {config.site.name}
        </title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={2}>
          <div className="page-wrapper">
            <h1 className="page-title">
              Lakitutka ja tietosuoja
            </h1>
            <Divider />
            <p>
              Lakitutka on Turun yliopiston
              oikeustieteellisen tiedekunnan ja tutkimuksen
              IT-yksikön tuottama palvelu, jonka avulla voi
              tarkastella lainvalmistelussa syntyneitä
              julkisia asiakirjoja. Lakitutka-palvelussa ei
              tuoteta uutta tietoa, vaan kyseessä on tietoja
              yhdistelevä hakukone, jolla voi etsiä
              valtioneuvoston hankeikkunassa ja eduskunnan
              internet-sivuilla julkaistuja,
              lainsäädäntöhankkeisiin liittyviä julkisia
              valmisteluasiakirjoja. Näitä asiakirjoja ovat
              esimerkiksi hallituksen esitykset,
              lausuntokierrosten lausunnot, valiokuntien
              lausunnot ja mietinnöt sekä
              eduskuntakeskustelut.
            </p>
            <p>
              Käsiteltävät henkilötiedot jakautuvat kahteen
              ryhmään: Lakitutka-palvelun käyttäjistä
              kerättäviin tietoihin, ja Lakitutkalla
              haettavien lainvalmisteluaineistojen
              sisältämiin henkilötietoihin.
            </p>
            <h2>1. Rekisterinpitäjä</h2>
            <p>
              Turun yliopisto
              <br />
              20014 Turun yliopisto
              <br />
              p. 029 450 5000
            </p>
            <p>
              Lakitutkaa koskevissa asioissa
              sähköpostiyhteydenotot
              <br />
              lakitutka@utu.fi
            </p>
            <h2>2. Tietosuojavastaavan yhteystiedot</h2>
            <p>
              Turun yliopiston tietosuojavastaava
              tietosuoja@utu.fi
            </p>
            <h2>
              3. Henkilötietojen käsittelyn tarkoitukset
              sekä käsittelyn oikeusperuste
            </h2>
            <h3>
              3.1. Lainvalmisteluaineisto
            </h3>
            <p>
              Lakitutka on Turun yliopiston palvelu, johon
              on koottu lainvalmistelussa syntyneet julkiset
              asiakirjat valtioneuvoston hankeikkunan
              avoimesta rajapinnasta ja eduskunnan avoime
              datan verkkopalvelusta. Tietojen käsittelyn
              tarkoitus on helpottaa
              lainvalmisteluasiakirjojen saatavuutta
              keräämällä ne yhteen hakupalveluun, edistää
              lainvalmistelun läpinäkyvyyttä sekä tarjota
              tutkimustyökaluja lainvalmisteluasiakirjoja
              tutkiville tutkijoille.
            </p>
            <p>
              Lakitutkasta on mahdollista ladata
              havaintomatriiseja, jotka voivat sisältää
              lainvalmisteluun osallistuneiden henkilöiden
              nimiä, titteleitä ja taustaorganisaatioita.
              Havaintomatriisien latausmahdollisuuden
              tarkoitus on helpottaa lainvalmisteluun
              osallistuneiden tahojen selvittämistä ja siten
              edistää avoimuutta lainvalmistelussa.
            </p>
            <p>
              Lakitutka sisältää henkilötietoja, joita
              käsitellään tietosuoja-asetuksen mukaisen
              yleistä etua koskevan tehtävän vuoksi.
            </p>
            <h3>
              3.2. Verkkosivujen käyttäjien tiedot
            </h3>
            <p>
              Lakitutkan verkkosivujen käyttäjien
              sivulatauksista tallentuu tieto 
              palvelun toimintaa
              koskevien yhteenvetojen tekemiseksi
              sekä virhetilanteiden selvittämiseksi.
            </p>
            <p>
              Käytämme verkkosivustollamme Matomo-analytiikkatyökalua 
              evästeettömästi. Keräämme tiedot anonyymeina, 
              kuten IP-osoitteen (anonymisoituna), vierailun ajankohdan, 
              katsotut sivut ja sivustolla vietetyn ajan. Kerätyistä 
              tiedoista ei voi tunnistaa yksittäistä henkilöä. 
              Nämä tiedot tallennetaan ja käsitellään ainoastaan 
              Turun yliopiston omilla palvelimilla Suomessa. 
              Emme jaa tietoja kolmansille osapuolille.
            </p>
            <p>
              Palvelun käytöstä syntyy lokimerkintöjä, joita käytetään palvelun 
              tietoturvallisuudesta huolehtimiseen, palvelun tekniseen kehittämiseen
              sekä vikatilanteiden havaitsemiseen, estämiseen ja selvittämiseen 
              (Tietoyhteiskuntakaari (917/2014) 138§, 141§, 144§, 272§). 
              Lokeja säilytetään näihin tarkoituksiin tarvittava aika eikä 
              niitä käytetä muuhun tarkoitukseen.
            </p>
            <p>
              Lakitutkan palautelomakkeesta saatuja tietoja käsitellään
              palvelun kehittämiseksi. Palautelomakkeella mahdollisesti syötettyjä
              sähköpostiosoitteita käytetään palautteeseen vastaamiseen. Muita
              henkilötietoja ei palautelomakkeella kerätä.
            </p>
            <h2>4. Millaisia tietoja käsitellään</h2>
            <p>
              Lainvalmisteluasiakirjojen osalta aineiston
              sisältämiä henkilötietoja ovat henkilöiden
              nimi, titteli ja taustaorganisaatio.
            </p>
            <p>
              Lakitutkan verkkosivujen osalta käsitellään
              sellaisia käyttäjien itsensä antamia tietoja,
              joita he palautelomakkeeseen täyttävät.
              Palautelomake on mahdollista täyttää ilman
              henkilötietoja.</p>
            <p>Sivulatauksista kertovan lokin
              tiedoista koostetaan yhteenvetoja
              kävijämääristä ja hakutermeistä. Analytiikkaan
              ei sisällytetä yksilöiviä IP-osoitteita.
            </p>
            <h2>5. Mistä tiedot ovat peräisin</h2>
            <p>
              Lakitutkassa olevien julkisten asiakirjojen
              sisältämät henkilötiedot on kerätty
              valtioneuvoston hankeikkunan avoimesta
              rajapinnasta ja eduskunnan avoimen datan
              verkkopalvelusta.
            </p>
            <h2>6. Henkilötietojen säilytysaika</h2>
            <p>
              Lainvalmisteluasiakirjojen sisältämiä
              henkilötietoja säilytetään niin kauan, kun
              Lakitutka-palvelu on toiminnassa.
            </p>
            <h2>7. Rekisteröidyn oikeudet</h2>
            <p>
              Tietosuojavaltuutetun internet-sivulta saat
              lisätietoa sinua koskevista oikeuksista:
              <a href="https://tietosuoja.fi/rekisteroidyn-oikeudet-eri-tilanteissa">
                tietosuoja.fi/rekisteroidyn-oikeudet-eri-tilanteissa
              </a>
            </p>
            <p>
              Rekisteröidyn oikeuksia koskevissa asioissa
              tulee olla yhteydessä kohdassa 1 mainittuun
              tahoon.
            </p>
            <h3>Oikeus saada pääsy henkilötietoihin</h3>
            <p>
              Sinulla on oikeus saada tietoa siitä, mihin
              tarkoitukseen henkilötietojasi kerätään ja
              miten niitä käsitellään, sekä oikeus tarkistaa
              sinua koskevat tiedot.
            </p>
            <h3>Oikeus tietojen oikaisemiseen</h3>
            <p>
              Sinulla on oikeus vaatia, että sinua koskevat
              epätarkat ja virheelliset henkilötiedot
              korjataan. Sinua koskevat virheelliset tiedot
              tulee pyynnöstäsi korjata ja epätäydelliset
              tiedot tulee täydentää.
            </p>
            <h3>Oikeus tietojen poistamiseen</h3>
            <p>
              Sinulla on oikeus vaatia, että sinua koskevat
              henkilötiedot poistetaan.
            </p>
            <p>
              Rekisterinpitäjän ei kuitenkaan ole pakko
              poistaa henkilötietojasi, jos ne ovat
              perustellusti tarpeellisia seuraavia
              tarkoituksia varten:
            </p>
            <ul>
              <li>sananvapaus ja tiedon välittäminen</li>
              <li>lain noudattaminen</li>
              <li>
                rekisterinpitäjälle kuuluvan julkisen vallan
                käyttäminen
              </li>
              <li>
                yleinen etu – esimerkiksi arkistointi,
                tutkimus tai tilastointi
              </li>
              <li>
                oikeusvaateen laatiminen, esittäminen tai
                puolustaminen.
              </li>
            </ul>
            <h3>Oikeus käsittelyn rajoittamiseen</h3>
            <p>
              Jos vastustat käsittelyä, kyseenalaistat
              käsittelyn laillisuuden tai tietojen
              oikeellisuuden, tai mikäli
              tarvitset henkilötietojasi oikeusprosessissa,
              sinulla on oikeus pyytää käsittelyn
              rajoittamista, kunnes asia on selvitetty.
            </p>
            <h3>Vastustamisoikeus</h3>
            <p>
              Joissakin tilanteissa voit vastustaa
              henkilötietojesi käsittelyä ja pyytää, että
              tietojasi ei käsiteltäisi ollenkaan.
              Vastustaminen on mahdollista, jos
              rekisterinpitäjä käsittelee tietojasi yleisen
              edun tai julkisen vallan vuoksi tai oikeutetun
              etunsa perusteella.
            </p>
            <p>
              Voit vastustaa tietojesi käsittelyä, jos
              sinulla on siihen jokin henkilökohtaiseen
              tilanteeseesi liittyvä erityinen syy. Tällöin
              emme voi käsitellä henkilötietojasi, paitsi
              jos käsittelyyn on olemassa huomattavan tärkeä
              ja perusteltu syy, joka syrjäyttää sinun
              etusi, oikeutesi ja vapautesi tai jos tietojen
              käsittely on tarpeen oikeusvaateen
              laatimiseksi, esittämiseksi tai
              puolustamiseksi.
            </p>
            <h3>
              Oikeus tehdä valitus tietosuojavaltuutetun
              toimistoon
            </h3>
            <p>
              Sinulla on oikeus tehdä valitus
              tietosuojavaltuutetun toimistoon, jos
              tietojesi käsittelyssä on rikottu voimassa
              olevaa tietosuojalainsäädäntöä.
            </p>
            <h3>
              Tietosuojavaltuutetun toimiston yhteystiedot
            </h3>
            <p>
              Käyntiosoite: Lintulahdenkuja 4, 00530
              Helsinki
              <br />
              Postiosoite: PL 800, 00531 Helsinki
              <br />
              Puhelinvaihde: 029 566 6700
              <br />
              Kirjaamo: 029 566 6768
              <br />
              Sähköposti (kirjaamo): tietosuoja(at)om.fi
              <br />
              <a href="https://tietosuoja.fi/yhteystiedot">
                tietosuoja.fi/yhteystiedot
              </a>
            </p>
          </div>
        </Paper>
      </Section>
    </>
  );
};
export default PrivacyPolicy;
