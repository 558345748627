import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './TimeLineChart.css';
import {
  XYPlot,
  XAxis,
  YAxis,
  MarkSeries,
  Hint,
} from 'react-vis';
import { colorVariant } from 'utils/colorTools';
import config from 'config';
import Tip from 'components/Tip';
import { translation } from 'utils/translate';
import {
  getDocumentUrl,
  getLawUrl,
} from 'utils/commonTools';
import { useSelector } from 'react-redux';

const ZOOOMED_OUT = 2

const docGroups = Object.keys(config.docGroups);

const generateTitle = (item, docGroup, lang) => {
  if (docGroup === 'laws') {
    return [item.laki_id, item.title].join(': ');
  }
  if (docGroup === 'lausuntokierros_asiakirjat') {
    return `${translation(item.tyyppi, lang)}, ${item.laatija}`;
  }
  return item.title;
};

const generateHint = (value, lang) => {
  const title = value.title || value.tunnus || value.id;
  return (
    <div
      // "rv-hint__content" is a react-vis class
      className="rv-hint__content timeline-hint"
    >
      <div className="timeline-hint__docgroup">
        {value.docGroup === 'lausuntokierros' ? translation("doc_groups", value.docGroup, "optionalName", lang) :
          translation("doc_groups", value.docGroup, "name", lang)}
      </div>
      <div className="timeline-hint__title">
        {title.length > 80
          ? `${title.slice(0, 78)}...`
          : title}
      </div>
      <div className="timeline-hint__date">
        {value.x.getDate()}.{value.x.getMonth() + 1}.
        {value.x.getFullYear()}
      </div>
    </div>
  );
};

// MAIN COMPONENT
const TimeLineChart = ({
  data,
  scroll,
  activeDoc,
  setActiveDocGroups,
}) => {
  const history = useHistory();
  const [hint, setHint] = useState(null);
  const [activeGroups, setActiveGroups] =
    useState(docGroups);
  const [dateRange, setDateRange] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(ZOOOMED_OUT); // now only one zoomlevel

  const lang = useSelector((store) => store.lang)

  // preprocess data and apply docgroup filter
  const allEntries = useMemo(
    () =>
      Object.entries(data)
        .filter(([docGroup]) =>
          activeGroups.includes(docGroup),
        )
        .flatMap(([docGroup, items]) =>
          items
            // 1st round: set needed datafields
            .map((item) => ({
              // TODO: fix this
              id: item.doc_id || item.laki_id || item.id,
              date:
                item.date ||
                item.aloitus_paiva || // lausuntokierros date types
                item.asettamis_paiva || // lausuntokierros date types
                item.julkaisuaika || // lausuntokierros date types
                item.laadittu || // eduskunta docs date type
                item.vahvistettu || // laws
                item.laatimispaiva, // lausuntokierros nested doc date type
              docGroup,
              tunnus: item.tunnus,
              // Show laki_id before law's title and 'laatija' in lausunnot instead of title
              title: generateTitle(item, docGroup, lang),
            })),
        ),
    [activeGroups, data, lang],
  );

  // filter out dateless items
  const entriesWithDate = useMemo(
    () => allEntries.filter(({ date }) => date),
    [allEntries],
  );

  // filter out dateless items
  const filteredView = useMemo(
    () =>
      entriesWithDate.filter((item) => {
        if (dateRange) {
          const currentDate = new Date(item.date);
          if (
            currentDate > dateRange[0] &&
            currentDate < dateRange[1]
          ) {
            return item;
          }
          return null;
        }
        return item;
      }),
    [entriesWithDate, dateRange],
  );

  const filteredViewNotNull = useMemo(
    () =>
      filteredView.length === 0
        ? entriesWithDate
        : filteredView,
    [entriesWithDate, filteredView],
  );

  // finally process data for react-vis chart
  const chartData = useMemo(
    () =>
      filteredViewNotNull.map((item) => ({
        x: new Date(item.date),
        y: 1,
        color: colorVariant(
          docGroups.indexOf(item.docGroup),
        ),
        size: `${item.id}` === activeDoc ? 2 : 1,
        url:
          item.docGroup === 'laws'
            ? getLawUrl(item.id)
            : getDocumentUrl(item.docGroup, item.id),
        ...item,
      })),
    [activeDoc, filteredViewNotNull],
  );

  // store chart height to state to prevent resizing while filtering
  const [initialHeight] = useState(
    chartData.length < 50 ? 200 : 300,
  );

  const toggleActiveGroups = (group) =>
    setActiveGroups(
      activeGroups.includes(group)
        ? [...activeGroups.filter((item) => item !== group)]
        : [...activeGroups, group],
    );

  const toggleSoloGroup = (group) => {
    setActiveGroups(
      activeGroups.includes(group) &&
        activeGroups.length === 1
        ? docGroups
        : [group],
    );
  };

  const activateAllGroups = () =>
    setActiveGroups(docGroups);

  useEffect(() => {
    setActiveDocGroups(
      activeGroups.filter(
        (group) => data[group] && data[group]?.length,
      ),
    );
    // eslint-disable-next-line
  }, [activeGroups]);

  // Vanilla js HACK for translating month names
  // in ReactVis timeline charts
  useEffect(() => {
    const monthMap = {
      January: 'Tammikuu',
      February: 'Helmikuu',
      March: 'Maaliskuu',
      April: 'Huhtikuu',
      May: 'Toukokuu',
      June: 'Kesäkuu',
      July: 'Heinäkuu',
      August: 'Elokuu',
      September: 'Syyskuu',
      October: 'Lokakuu',
      November: 'Marraskuu',
      December: 'Joulukuu',
    };
    const shortMap = {
      Jan: 'Tam',
      Feb: 'Hel',
      Mar: 'Maa',
      Apr: 'Huh',
      May: 'Tou',
      Jun: 'Kes',
      Jul: 'Hei',
      Aug: 'Elo',
      Sep: 'Syy',
      Oct: 'Lok',
      Nov: 'Mar',
      Dec: 'Jou',
      Mon: 'Ma',
      Tue: 'Ti',
      Wed: 'Ke',
      Thu: 'To',
      Fri: 'Pe',
      Sat: 'La',
      Sun: 'Su',
    };
    const ticks = document.getElementsByClassName(
      'rv-xy-plot__axis__tick',
    );
    // eslint-disable-next-line no-restricted-syntax
    for (const el of ticks) {
      const textNode = el.childNodes[1];
      if (textNode) {
        const orig = textNode.innerHTML;
        // if text is all letters, try translating long month name
        if (orig.match(/^[a-z]+$/i)) {
          textNode.innerHTML = monthMap[orig] || orig;
        }
        // if text starts with 3 letters, try translating short month/weekday
        if (orig.match(/^[a-z]{3} /i)) {
          const short =
            shortMap[orig.substr(0, 3)] ||
            orig.substr(0, 3);
          textNode.innerHTML = short + orig.slice(3);
        }
      }
    }
  }, [chartData]);

  let tickFormatterYearCache = null;

  function tickFormatter(value, index, scale) {
    const currentYear = value.getYear();
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    };

    // show year in first and last tick and everytime year has changed
    if (
      index === 0 ||
      currentYear !== tickFormatterYearCache ||
      index + 1 === scale.ticks().length // index === tickTotal
    ) {
      tickFormatterYearCache = currentYear;
      return value.toLocaleDateString('fi-FI', options);
    }

    delete options.year;
    return value.toLocaleDateString('fi-FI', options);
  }

  function autoPartial(fn) {
    const collect = (boundArgs, ...args) => {
      const collectedArgs = boundArgs.concat(args);
      return collectedArgs.length >= fn.length
        ? fn.apply(null, collectedArgs)
        : collect.bind(null, collectedArgs);
    };
    return collect.bind(null, []);
  }

  function getRelativeLocationFromRange(value, r) {
    return (value - r[0]) / (r[1] - r[0]);
  }

  function getValueFromRange(percentage, r) {
    return percentage * (r[1] - r[0]) + r[0];
  }

  function timeStampToDate(timeStamp) {
    return new Date(timeStamp * 1000);
  }

  function getDateRange(value, r, zoomLevel) {
    const margin = zoomLevel * 0.1;

    setZoomLevel(zoomLevel);

    return [
      timeStampToDate(value - margin * (r[1] - r[0])),
      timeStampToDate(value + margin * (r[1] - r[0])),
    ];
  }

  // custom zooming functionality for react-vis chart
  const zoomChart = (evt) => {
    if (evt.deltaY > 0) {
      setDateRange(null);
      setZoomLevel(ZOOOMED_OUT);
      // TODO: implement zooming out properly if you want to use more zoomlevels
      return;
    }

    if (zoomLevel === 1) {
      return;
    }

    var rect = evt.target.getBoundingClientRect();

    const relativeWheelLocation =
      getRelativeLocationFromRange(evt.pageX, [
        rect.left,
        rect.right,
      ]);

    const orderedChartData = chartData.sort(
      (a, b) => a.x.getTime() - b.x.getTime(),
    );

    const start = Math.floor(
      new Date(orderedChartData[0].date).getTime() / 1000,
    );
    const end = Math.floor(
      new Date(
        chartData[orderedChartData.length - 1].date,
      ).getTime() / 1000,
    );

    const relativeDate = getValueFromRange(
      relativeWheelLocation,
      [start, end],
    );

    setDateRange(
      getDateRange(
        relativeDate,
        [start, end],
        zoomLevel - 1,
      ),
    );
  };

  useEffect(() => {
    let plot = document.getElementsByClassName(
      'rv-xy-plot__inner',
    );
    if (plot.length > 0) {
      plot[0].addEventListener('wheel', (event) => {
        if (zoomLevel !== ZOOOMED_OUT) {
          event.preventDefault();
        }
      });
    }// eslint-disable-next-line
  }, []);

  const setChartSizeRange = (chartDataLength) =>
    chartDataLength < 50 ? [8, 12] : [6, 9];

  const chartDateFromOneDay = (data) =>
    data.every(
      (el) => el.x.getTime() === chartData[0].x.getTime(),
    );

  const setTotalTicks = (data) =>
    chartDateFromOneDay(data) ? 1 : 10;

  const zoomOut = () => {
    setZoomLevel(ZOOOMED_OUT)
    setDateRange(null)
  }

  return (
    <>
      {zoomLevel !== ZOOOMED_OUT ? <button onClick={() => zoomOut()}>{translation("timeline", "zoom", lang)}</button> : null}
      <XYPlot
        className="timeline-chart"
        xType="time"
        yType={chartData.length > 5 ? 'linear' : 'ordinal'}
        width={1104}
        margin={{ left: 20, top: 20, right: 20 }}
        height={initialHeight}
        stackBy="y"
        onWheel={(e) => zoomChart(e)}
      >
        <XAxis
          tickValues={
            // show exact date in only one tick if data from one day
            chartData.length > 0 &&
              chartDateFromOneDay(chartData)
              ? [chartData[0].x]
              : null // will throw error, but knows how to set default tickvalues (10)
          }
          tickTotal={setTotalTicks(chartData)}
          tickFormat={autoPartial(
            tickFormatter,
            tickFormatterYearCache,
          )}
        />
        <YAxis hideTicks hideLine />
        <MarkSeries
          data={chartData}
          sizeRange={setChartSizeRange(chartData.length)}
          colorType="literal"
          stroke="#0005"
          onValueMouseOver={(value) => setHint(value)}
          onValueMouseOut={() => setHint(null)}
          onValueClick={(datapoint) => {
            if (datapoint.url) {
              if (datapoint.url.startsWith('http')) {
                window.open(
                  datapoint.url,
                  '_blank',
                  'noopener',
                );
              } else {
                scroll();
                history.push(datapoint.url);
              }
            }
          }}
        />
        {hint ? (
          <Hint value={hint}>{generateHint(hint, lang)}</Hint>
        ) : null}
      </XYPlot>

      <div className="timeline-legend">
        {docGroups
          .map((group, idx) => ({ group, idx }))
          .filter(
            ({ group }) =>
              data[group] && data[group].length,
          )
          .map(({ group, idx }) => (
            <Tip
              title={
                <>
                  {translation("timeline", "tooltip", group, lang)}
                  <br />
                  <br />
                  <i>
                    {translation("timeline", "tooltip", "footer", lang)}
                  </i>
                </>
              }
              key={group}
            >
              <button
                type="button"
                className={
                  activeGroups.includes(group)
                    ? 'timeline-legend__item timeline-legend__item--active'
                    : 'timeline-legend__item'
                }
                onClick={() => toggleActiveGroups(group)}
                onDoubleClick={() => toggleSoloGroup(group)}
              >
                <div
                  className="timeline-legend__item__color"
                  style={{
                    background:
                      activeGroups.includes(group) &&
                      colorVariant(idx),
                  }}
                />
                {group === 'lausuntokierros' ? translation("doc_groups", group, "optionalName", lang) :
                  translation("doc_groups", group, "name", lang)}
              </button>
            </Tip>
          ))}

        <button
          type="button"
          className="timeline-legend__item"
          onClick={() => activateAllGroups()}
        >
          <div
            className="timeline-legend__item__color"
            style={{ background: 'black' }}
          />
          {translation("timeline", "show_all", lang)}
        </button>
      </div>
      <div className="timeline-postfix">
        <span className="timeline-postfix__item">
          {allEntries.length} {translation("timeline", "documents", lang)}
          {allEntries.length !== entriesWithDate.length && (
            <Tip title={translation("timeline", "hidden", lang)}>
              <span className="tippable">
                {' '}
                ({translation("timeline", "joista", lang)}{' '}
                {allEntries.length -
                  entriesWithDate.length}{' '}
                {allEntries.length -
                  entriesWithDate.length ===
                  1
                  ? translation("timeline", "no_date", lang)
                  : translation("timeline", "no_dates", lang)}
                )
              </span>
            </Tip>
          )}
        </span>
      </div>
    </>
  );
};

TimeLineChart.propTypes = {
  data: PropTypes.shape({}).isRequired,
  scroll: PropTypes.func.isRequired,
  activeDoc: PropTypes.string.isRequired,
  setActiveDocGroups: PropTypes.func.isRequired,
};

export default TimeLineChart;
