import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import config from 'config';
import Section from 'components/Section';
import { Divider, Paper } from '@material-ui/core';
import { translation } from 'utils/translate';
import { useSelector } from 'react-redux';

const Accessibility = () => {
  const lang = useSelector((store) => store.lang)

  // scroll to top when entering page view
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      <Helmet>
        <title>{translation("accessability", "short_title", lang)} | {config.site.name}</title>
      </Helmet>
      <Section className="section--page">
        <Paper elevation={2}>
          <div className="page-wrapper">
            <h1 className="page-title">
            {translation("accessability", "long_title", lang)}
            </h1>
            <Divider />
            <h2>
              1
              {' '}
              {translation("accessability", "section_1_title", lang)}
            </h2>
            <p>
              {translation("accessability", "section_1_texta", lang)}
            </p>
            <p>
              {translation("accessability", "section_1_textb", lang)}
            </p>
            <h3>
              1
              {' '}
              {translation("accessability", "section_1_1_title", lang)}
            </h3>
            <p>
              {translation("accessability", "section_1_1_texta", lang)}
              {' '}
              <a href="https://www.w3.org/TR/WCAG21/">
                Web Content Accessibility Guidelines (WCAG)
                2.1
              </a>
              {translation("accessability", "section_1_1_textb", lang)}
            </p>
            <h3>
              1.2
              {' '}
              {translation("accessability", "section_1_2_title", lang)}
            </h3>
            <p>
              {translation("accessability", "section_1_2_text", lang)}
            </p>
            <h2>
              2
              {' '}
              {translation("accessability", "section_2_title", lang)}
            </h2>
            <p>
              {translation("accessability", "section_2_texta", lang)}
            </p>
            <p>
              {translation("accessability", "section_2_textb", lang)}
            </p>
            <p>
              {translation("accessability", "section_2_textc", lang)}
            </p>
            <p>
              {translation("accessability", "section_2_textd", lang)}
            </p>
            <p>
              {translation("accessability", "section_2_texte", lang)}
            </p>
            <h2>
              3
              {' '}
              {translation("accessability", "section_3_title", lang)}
            </h2>
            <p>
              {translation("accessability", "section_3_texta", lang)}
            </p>
            <p>
              {translation("accessability", "section_3_textb", lang)}
              {' '}
              <a href={translation("accessability", "section_3_link", lang)}>
                {translation("accessability", "section_3_linktext", lang)}
              </a>
              . {translation("accessability", "section_3_textc", lang)}
            </p>
            <h2>
              3.1 {translation("accessability", "section_3_1_title", lang)}
            </h2>
            <p>
              {translation("accessability", "section_3_1_texta", lang)}
            </p>
            <p>
              {translation("accessability", "section_3_1_textb", lang)}
            </p>
            <p>
              <a href={translation("accessability", "section_3_1_link", lang)}>
                {translation("accessability", "section_3_1_linktext", lang)}
              </a>
            </p>
            <p>
              {translation("accessability", "section_3_1_email", lang)}
            </p>
            <h2>
              4
              {' '}
              {translation("accessability", "section_4_title", lang)}
            </h2>
            <p>
              <a href="https://www.utu.fi/fi/yliopisto/saavutettavuus">
                {translation("accessability", "accessibility_in_utu", lang)}
              </a>
            </p>
          </div>
        </Paper>
      </Section>
    </>
  );
};

export default Accessibility;
