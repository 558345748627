import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Paper,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Button,
  Input,
  Radio,
  RadioGroup,
  FormLabel,
  FormControlLabel,
} from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import CancelIcon from '@material-ui/icons/Cancel';
import Tip from 'components/Tip';
import {
  initializeFilters,
  initializeFiltersWithDefaultValue,
} from 'utils/commonTools';
import { translateKeyword, translation } from 'utils/translate';
import config from 'config';
import './PreFilters.css';

/* PREFILTERS COMPONENT */

const PreFilters = ({
  docGroup,
  handlePreFiltersSubmit,
  handlePreFiltersClear,
}) => {
  const facetConstants = useSelector(
    (store) => store.facetConstants,
  );

  const lang = useSelector((store) => store.lang);

  // initialize docGroup-specific constants
  const constants = useMemo(
    () => facetConstants[docGroup] || {},
    [docGroup, facetConstants],
  );
  const ymin = constants.min_year || 1970;
  const ymax = constants.max_year || 1970;
  // Generate array of years from ymin to ymax
  const years = [...Array(ymax - ymin + 1)].map(
    (_, i) => i + ymin,
  );
  const {
    multiSelectFields,
    radioSelectFields,
  } = config.docGroups[docGroup];

  // Generate initial empty values object state for preFilters
  const preFiltersInit = useMemo(
    () => ({
      ...initializeFilters(multiSelectFields),
      ...initializeFiltersWithDefaultValue(
        radioSelectFields.map((field) => field.id),
        '',
      ),
      term: '',
      year_start: '',
      year_end: '',
      tunnus: '',
    }),
    [multiSelectFields, radioSelectFields],
  );

  const [preFiltersInput, setPreFiltersInput] = useState(
    preFiltersInit,
  );
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handlePreFiltersChange = (event) => {
    setPreFiltersInput({
      ...preFiltersInput,
      ...{ [event.target.name]: event.target.value },
    });
  };

  const handleClear = () => {
    setPreFiltersInput(preFiltersInit);
    handlePreFiltersClear();
  };

  const removeChipFromPreFilters = (val, field) => {
    setPreFiltersInput({
      ...preFiltersInput,
      ...{
        [field]: preFiltersInput[field].filter(
          (item) => item !== val,
        ),
      },
    });
  };

  return (
    <div className="prefilters-wrapper">
      <div className="prefilters-button-container">
        <Tip title={translation("prefilter", "tooltip", lang)}>
          <Button
            id="prefilters-toggler"
            className="prefilters-toggler"
            disableTouchRipple
            onClick={() => toggleOpen()}
          >
            <FilterIcon className="button-icon" />
            {translation("prefilter", "name", lang)}
          </Button>
        </Tip>
      </div>
      {!isOpen ? (
        <div className="prefilters-form-container" />
      ) : (
        <div className="prefilters-form-container prefilters-form-container--open">
          <Paper elevation={2} className="prefilters-form">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <h4 className="prefilters__title">
                  {translation("prefilter", "name", lang) + ' ' + translation('doc_groups', docGroup, 'name', lang)}
                </h4>
              </Grid>

              {/* PREFILTER MULTISELECTS */}
              <Grid item xs={12} sm={6} md={4}>
                {multiSelectFields.map((field) => (
                  <FormControl
                    className="prefilters__form-control"
                    key={field}
                  >
                    <InputLabel htmlFor="prefilters-{field}">
                      {translateKeyword(field, lang)}
                    </InputLabel>
                    <Select
                      multiple
                      className="prefilters-form__input prefilters-form__multiselect"
                      value={preFiltersInput[field]}
                      onChange={handlePreFiltersChange}
                      input={
                        <Input
                          id="prefilters-{field}"
                          name={field}
                        />
                      }
                      renderValue={(selected) => (
                        <div className="auto-chips">
                          {selected.map((val) => (
                            <Chip
                              key={val}
                              label={val}
                              className="auto-chip"
                              clickable
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                              onDelete={() =>
                                removeChipFromPreFilters(
                                  val,
                                  field,
                                )
                              }
                            />
                          ))}
                        </div>
                      )}
                    >
                      {Object.keys(constants[field]).map(
                        (val) => (
                          <MenuItem key={val} value={val}>
                            {val}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  </FormControl>
                ))}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                {/* PREFILTER START YEAR */}
                <FormControl className="prefilters__form-control--50">
                  <InputLabel
                    htmlFor="year_start"
                    shrink={!!preFiltersInput.year_start}
                  >
                    {translation("prefilter", "from_year", lang)}
                  </InputLabel>
                  <Select
                    value={preFiltersInput.year_start}
                    className="prefilters-form__input prefilters-form__rangeselect"
                    onChange={handlePreFiltersChange}
                    inputProps={{
                      name: 'year_start',
                      id: 'year_start',
                    }}
                  >
                    <MenuItem value="">
                      <i>{translation("prefilter", "not_selected", lang)}</i>
                    </MenuItem>
                    {years
                      .filter(
                        (val) =>
                          val <= preFiltersInput.year_end ||
                          !preFiltersInput.year_end,
                      )
                      .map((val) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    {years
                      .filter(
                        (val) =>
                          preFiltersInput.year_end &&
                          val > preFiltersInput.year_end,
                      )
                      .map((val) => (
                        <MenuItem
                          key={val}
                          value={val}
                          disabled
                        >
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* PREFILTER END YEAR */}
                <FormControl className="prefilters__form-control--50">
                  <InputLabel
                    htmlFor="year_end"
                    shrink={!!preFiltersInput.year_end}
                  >
                    {translation("prefilter", "to_year", lang)}
                  </InputLabel>
                  <Select
                    value={preFiltersInput.year_end}
                    className="prefilters-form__input prefilters-form__rangeselect"
                    onChange={handlePreFiltersChange}
                    inputProps={{
                      name: 'year_end',
                      id: 'year_end',
                    }}
                  >
                    <MenuItem value="">
                      <i>{translation("prefilter", "not_selected", lang)}</i>
                    </MenuItem>
                    {years
                      .filter(
                        (val) =>
                          preFiltersInput.year_start &&
                          val < preFiltersInput.year_start,
                      )
                      .map((val) => (
                        <MenuItem
                          key={val}
                          value={val}
                          disabled
                        >
                          {val}
                        </MenuItem>
                      ))}
                    {years
                      .filter(
                        (val) =>
                          val >=
                          preFiltersInput.year_start ||
                          !preFiltersInput.year_start,
                      )
                      .map((val) => (
                        <MenuItem key={val} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                {/* PREFILTER SEARCH TERM */}
                <FormControl className="prefilters__form-control">
                  <TextField
                    id="prefilters-term"
                    label={translation("prefilter", "search_term", lang)}
                    className="prefilters-form__input prefilters-form__searchterm"
                    name="term"
                    value={preFiltersInput.term}
                    onChange={handlePreFiltersChange}
                  />
                </FormControl>
              </Grid>

              {/* PREFILTER-TUNNUS */}
              <Grid
                className="prefilters-button-wrapper"
                item
                xs={12}
                sm={6}
                md={4}
              >
                <FormControl className="prefilters__form-control">
                  <TextField
                    id="prefilters-tunnus"
                    label={translation("prefilter", "diaarinumerot", lang)}
                    name="tunnus"
                    multiline
                    rowsMax="5"
                    className="prefilters-form__input prefilters-form__tunnus"
                    value={preFiltersInput.tunnus}
                    onChange={handlePreFiltersChange}
                  />
                </FormControl>
              </Grid>

              {/* PREFILTER RADIOBUTTON */}
              {radioSelectFields.map((radioField) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={radioField.id}
                >
                  <FormControl
                    className="prefilters__form-control"
                    key={radioField.id}
                  >
                    <Tip
                      title={translation("prefilter", radioField.name, "tooltip", lang)}
                    >
                      <FormLabel
                        component="legend"
                        className="prefilters__legend tippable"
                      >
                        {translation("prefilter", radioField.name, "name", lang)}
                      </FormLabel>
                    </Tip>
                    <RadioGroup
                      aria-label={radioField.name}
                      value={preFiltersInput[radioField.id]}
                      name={radioField.id}
                      onChange={handlePreFiltersChange}
                    >
                      <FormControlLabel
                        value=""
                        control={<Radio />}
                        label={translation("prefilter", "no_filters", lang)}
                        key="Ei rajausta"
                      />
                      {radioField.fields.map((field) => (
                        <FormControlLabel
                          value={field}
                          control={<Radio />}
                          label={translation("prefilter", radioField.name, field, lang)}
                          key={field + radioField.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ))}

              {/* PREFILTER SUBMIT/RESET */}
              <Grid
                className="prefilters-button-wrapper"
                item
                xs={12}
                sm={12}
                md={12}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="prefilters-button submit-prefilters"
                  onClick={(e) =>
                    handlePreFiltersSubmit(
                      e,
                      preFiltersInput,
                    )
                  }
                >
                  {translation("prefilter", "name", lang)}
                </Button>
                <Button
                  className="prefilters-button reset-prefilters"
                  onClick={handleClear}
                >
                  {translation("prefilter", "remove", lang)}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      )}
    </div>
  );
};

PreFilters.propTypes = {
  docGroup: PropTypes.string.isRequired,
  handlePreFiltersSubmit: PropTypes.func.isRequired,
  handlePreFiltersClear: PropTypes.func.isRequired,
};

export default PreFilters;
